// External Libraries
import React from "react";
import * as Icon from "react-feather";
import moment from "moment";
import { toast as alert } from "react-toastify";
import queryString from "query-string";

// Services
import ClientService from "../../../services/ClientService";
import ConfigurationService from "../../../services/ConfigurationService";
import PlaidService from "../../../services/PlaidService";

// Common Components
import WComponent from "../../common/WComponent";

// Components
// import ReconciliationTool from "./ReconciliationTool/ReconciliationTool";
import PlaidConnectionModal from "./PlaidConnectionModal";

// Styling
import "./connections.css";

const CONNECTIONS = {
	plaidConnection: {
		type: "plaidConnection",
		title: "Connect Bank Account",
		description: "Creates a new link to send to a client and have them connect their bank account to Workflow.",
		Icon: Icon.Key
	},
	qboConnection: {
		type: "qboConnection",
		title: "QBO Connection",
		description: "Allows tools like HST Reconciliation to work",
		Icon: Icon.RefreshCcw
	}
};

class Connections extends WComponent {
	constructor(props) {
		super(props);

		this.state = {
			isQBOConnected: false,

			showPlaidConnectionModal: false
		};
	}

	async componentDidMount() {
		let { clientId } = this.props;

		let { data } = await ClientService.getClient({ clientId });

		let isQBOConnected = data.client.qbo_company_id !== null;

		this.update({ isQBOConnected });
	}

	onConnect = async type => {
		let { clientId } = this.props;

		if (type === CONNECTIONS.qboConnection.type) {
			window.open(`${ConfigurationService.resolveApi().rest}/integration/qbo/auth`, "_blank");
			return;
		}

		if (type === CONNECTIONS.plaidConnection.type) {
			await this.update({
				showPlaidConnectionModal: true
			});
			return;
		}
	};

	onPlaidConnectionModalClose = () => {
		this.update({
			showPlaidConnectionModal: false
		});
	};

	renderConnectionOption(toolOption) {
		let { isQBOConnected } = this.state;
		let { type, title, description, Icon } = toolOption;

		return (
			<div className="connections__item" onClick={() => this.onConnect(type)}>
				<div className="connections__item__icon">
					<Icon size={36} />
				</div>
				<div className="connections__item__text">
					<div className="connections__item__text__title">{title}</div>
					<div className="connections__item__text__blurb">
						{/* {isQBOConnected ? "Connected - " : "Not Connected - "} */}
						{description}
					</div>
				</div>
			</div>
		);
	}

	render() {
		let { clientId } = this.props;
		let { showPlaidConnectionModal } = this.state;

		const connectionsOptionArray = Object.values(CONNECTIONS);

		return (
			<>
				<div className="container">
					<div className="container__body">
						<div className="connections">{connectionsOptionArray.map(connectionOption => this.renderConnectionOption(connectionOption))}</div>
					</div>
				</div>
				<PlaidConnectionModal clientId={clientId} show={showPlaidConnectionModal} onClose={this.onPlaidConnectionModalClose} />
			</>
		);
	}
}

export default Connections;
