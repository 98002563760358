// External Libraries
import React from "react";
import DatePicker from "react-datepicker";

// Services
import PlaidService from "../../../../services/PlaidService";

// Common Components
import WComponent from "../../../common/WComponent";
import Loader from "../../../common/Loader";
import SearchableDropdown from "../../../common/searchable_dropdown/SearchableDropdown";

// Styling
// import "./reconciliation-tool.css";

class TransactionsTool extends WComponent {
	constructor(props) {
		super(props);

		this.state = {
			// Input State
			startDate: new Date(),
			endDate: new Date(),
			plaidAccountId: null,

			// Data State
			transactions: [],

			// UI State
			loading: false
		};
	}

	componentDidMount() {}

	onPlaidAccountSelect = account => {
		this.update({
			plaidAccountId: account.id
		});
	};

	onStartDateSelect = date => {
		this.update({
			startDate: date
		});
	};

	onEndDateSelect = date => {
		this.update({
			endDate: date
		});
	};

	onGetRecords = async () => {
		let { clientId } = this.props;
		let { startDate, endDate, plaidAccountId, loading } = this.state;

		if (loading) {
			return;
		}

		this.update({
			loading: true
		});

		let transactions = await PlaidService.fetchTransactions({
			clientId,
			startDate,
			endDate,
			plaidAccountId,
			searchTerm: ""
		});

		// TODO - Rendering Transactions

		await this.update({
			transactions,
			loading: false
		});
	};

	render() {
		let { clientId } = this.props;
		let { transactions, startDate, endDate, loading } = this.state;

		return (
			<>
				<div className="container">
					<div className="container-header-title">
						<h1 className="container-header-title__name">Bank Records</h1>
					</div>

					<SearchableDropdown
						title="Select a Bank Account ..."
						type={"plaidAccounts"}
						onSelect={this.onPlaidAccountSelect}
						clientId={clientId}
						firstFocus
					/>

					<div className="eng-modal-datepicker-container">
						<span className="eng-modal-datepicker-title">Start Date</span>
						<DatePicker
							className="eng-modal-datepicker"
							selected={startDate !== "" ? new Date(startDate) : new Date()}
							onChange={this.onStartDateSelect}
							autoComplete="off"
							dateFormat="MMMM d, yyyy"
						/>
					</div>

					<div className="eng-modal-datepicker-container">
						<span className="eng-modal-datepicker-title">End Date</span>

						<DatePicker
							className="eng-modal-datepicker"
							selected={endDate !== "" ? new Date(endDate) : new Date()}
							onChange={this.onEndDateSelect}
							autoComplete="off"
							dateFormat="MMMM d, yyyy"
						/>
					</div>

					<div className="btn" onClick={this.onGetRecords}>
						Get Records
					</div>

					{loading && (
						<div className="container-loader">
							<Loader />
						</div>
					)}
				</div>
			</>
		);
	}
}

export default TransactionsTool;
