import React from "react";
import { ToastContainer, cssTransition } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./ToastifyCustom.css";

const CloseButton = ({ closeToast }) => (
	<button className="custom-toast__button" onClick={closeToast}>
		<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#FFFFFF" className="custom-toast__svg">
			<line x1="18" y1="6" x2="6" y2="18"></line>
			<line x1="6" y1="6" x2="18" y2="18"></line>
		</svg>
	</button>
);

const Fade = cssTransition({
	enter: "custom-toast__fade-enter",
	exit: "custom-toast__fade-exit",
	duration: [500, 500]
});

const Toast = () => (
	<ToastContainer
		position="top-right"
		transition={Fade}
		autoClose={2500}
		hideProgressBar
		newestOnTop
		closeOnClick
		rtl={false}
		pauseOnFocusLoss
		draggable
		pauseOnHover
		theme="dark"
		toastClassName="custom-toast__body"
		closeButton={CloseButton}
	/>
);

export default Toast;
