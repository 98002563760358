// External Libraries
import React from "react";
import * as Icon from "react-feather";
import _ from "lodash";
import { toast as alert } from "react-toastify";
import posed from "react-pose";

// Services
import UserService from "../../../services/UserService";
import EngagementService from "../../../services/EngagementService";

// Common Components
import ViewModal from "../ViewModal/ViewModal";
import WComponent from "../../common/WComponent";
import Checkbox from "../../common/checkbox/Checkbox";

import { DEFAULT_VIEWS } from "../../../constants/Views";

import "./view-list.css";

const Box = posed.div({
	visible: {
		width: 300,
		padding: 15,
		opacity: 1,
		transition: {
			duration: 200
		}
	},
	hidden: {
		width: 0,
		padding: 0,
		opacity: 0,
		transition: {
			duration: 100
		}
	}
});

const types = {
	ENGAGEMENT: "engagement",
	CLIENT: "client",
	TASK: "task"
};

const ALL_DEFAULT_VIEW = DEFAULT_VIEWS[0];

class ViewList extends WComponent {
	constructor(props) {
		super(props);

		this.state = {
			views: [],
			isViewChanged: false,

			savingView: false,
			overwritingView: false,

			showViews: true,
			minimizeViews: false,

			showViewModal: false,
			selectedViewId: null,
			viewObject: null,

			defaultViews: [...DEFAULT_VIEWS],
			currentDefaultViewSelected: null,
			showInactive: false
		};
	}

	componentDidMount = () => {
		this.resetComponent();
	};

	componentDidUpdate = async prevProps => {
		let viewChanged = prevProps.isViewChanged !== this.props.isViewChanged;

		if (viewChanged) {
			this.resetComponent();
		}
	};

	isEngagementViewType = () => {
		let { type } = this.props;
		return type && type.toLowerCase() === types.ENGAGEMENT;
	};

	resetComponent = async () => {
		let { selectedViewId, isViewChanged: isViewChangedFromState, currentDefaultViewSelected } = this.state;
		let { isViewChanged: isViewChangedFromProp, viewType } = this.props;

		let hasViewChanged = isViewChangedFromState || isViewChangedFromProp;

		await this.update({
			selectedViewId,
			viewType,
			isViewChanged: hasViewChanged,
			defaultViews: [],
			currentDefaultViewSelected,
			showInactive: false
		});

		// If no custom or default view is selected, then select the ALL_DEFAULT_VIEW
		if (!selectedViewId && !currentDefaultViewSelected) {
			this.onDefaultViewSelected(ALL_DEFAULT_VIEW);
		}

		await Promise.all([this.fetchDefaultViews(), this.fetchCustomViews()]);
	};

	fetchCustomViews = async () => {
		let { type } = this.props;
		let views = await EngagementService.fetchViews({ type });

		await this.update({
			views
		});
	};

	onCreateNewView = async () => {
		this.update({
			selectedViewId: null,

			showViewModal: true,
			isViewChanged: false
		});
	};

	onViewModalClose = async () => {
		this.update({
			showViewModal: false
		});
	};

	onViewDelete = async ({ viewId, status }) => {
		await this.update({
			savingView: true
		});

		let view = await EngagementService.updateView({ viewId, status });

		await this.update({
			showViewModal: false,
			isViewChanged: false,
			savingView: false
		});

		if (!view) {
			alert.success("An error occurred trying to delete this view.");
		} else {
			alert.success("View Deleted Successfully!");
		}

		await this.fetchCustomViews();
	};

	onViewSave = async ({ viewId, name, isShared, assignedUsers: assignedUsersForView }) => {
		let { savingView, overwritingView } = this.state;
		let { type } = this.props;

		let isNewView = viewId === null;

		if (savingView || overwritingView) {
			return;
		}

		await this.update({
			savingView: true
		});

		let schema = this.props.getSchema();

		schema = JSON.stringify(schema, null, 4);

		let userIds = assignedUsersForView.map(user => {
			return user.user_id;
		});

		let { userId } = UserService.getUserData();

		// Check if the requester user id exists in the list
		let [exists] = userIds.filter(id => {
			return id === userId;
		});

		// If they don't then add them to the list
		if (!exists) {
			userIds.push(userId);
		}

		let view = null;

		if (isNewView) {
			view = await EngagementService.createView({ name, schema, userIds, isShared, type });
		} else {
			view = await EngagementService.updateView({ viewId, name, schema, userIds, isShared, type });
		}

		await this.update({
			showViewModal: false,
			isViewChanged: false,
			savingView: false
		});

		if (!view) {
			alert.success("An error occurred trying to create a view.");
		} else {
			alert.success("View Created Successfully!");
		}

		await this.fetchCustomViews();
	};

	onDefaultViewSelected = async view => {
		let { showInactive } = this.state;

		await this.update({
			selectedViewId: null
		});

		if (this.props.onDefaultViewSelected) {
			this.props.onDefaultViewSelected({ view, showInactive });
		}

		this.update({
			currentDefaultViewSelected: view,
			isViewChanged: false,

			viewObject: view
		});
	};

	toggleShowInactive = async event => {
		await this.update({
			showInactive: event.target.checked
		});

		let { currentDefaultViewSelected, viewObject } = this.state;

		if (!viewObject) {
			return;
		}

		// Is a default view that is currently selected
		if (currentDefaultViewSelected) {
			this.onDefaultViewSelected(viewObject);
			return;
		}

		this.onViewSelected({ view: viewObject });
	};

	onViewEditSelected = async ({ event, view }) => {
		event.stopPropagation();
		event.preventDefault();

		await this.update({
			selectedViewId: view.id,
			showViewModal: true
		});
	};

	onViewSelected = async ({ event, view }) => {
		let { showInactive } = this.state;

		await this.update({
			selectedViewId: view.id,
			isViewChanged: false,
			currentDefaultViewSelected: null,

			viewObject: view
		});

		this.props.onViewSelected({ view, showInactive });
	};

	onOverwriteCurrentView = async () => {
		let { selectedViewId, savingView, overwritingView } = this.state;
		let { type } = this.props;

		if (savingView || overwritingView) {
			return;
		}

		this.update({
			overwritingView: true
		});

		// Retrieve the view "schema" from the parent
		// Example payload:
		// {
		// 	filters: { flowId, flowStateId, ... },
		// 	sort: { ... }
		// 	columns: { ... }
		// }
		let schema = this.props.getSchema();

		schema = JSON.stringify(schema, null, 4);

		let updatedView = await EngagementService.updateView({ viewId: selectedViewId, schema, type });

		if (!updatedView) {
			alert.success("An error occurred trying to overwrite this view.");
		} else {
			alert.success("View updated successfully!");
		}

		await this.update({
			isViewChanged: false,
			overwritingView: false
		});

		await this.fetchCustomViews();
	};

	isDefaultViewSelected = () => {
		return this.state.selectedViewId === null;
	};

	onToggleViews = () => {
		let { minimizeViews } = this.state;
		this.update({
			minimizeViews: !minimizeViews
		});
	};

	async fetchDefaultViews() {
		const flows = await EngagementService.fetchFlows({});
		const flowsMasked = flows.map(flow => ({ id: flow.id, code: flow.code, flowName: flow.name }));

		let defaultViews = [...DEFAULT_VIEWS, ...flowsMasked];

		this.update({ defaultViews });
	}

	renderDefaultViews() {
		let { defaultViews, currentDefaultViewSelected, selectedViewId } = this.state;

		if (!this.isEngagementViewType()) {
			return null;
		}

		return (
			<>
				{this.renderAdditionalFilters()}
				<div className="views-container__list__title">Default Views</div>
				{defaultViews.map(view => {
					let isSelected = currentDefaultViewSelected && currentDefaultViewSelected.id === view.id;

					return (
						<div
							className={`views-container__list__item ${isSelected ? "views-container__list__item--selected" : ""}`}
							onClick={() => this.onDefaultViewSelected(view)}
							key={view.flowName + "-" + view.id}
						>
							{view.flowName}
						</div>
					);
				})}
				<div className="views-container__list__title">Custom Views</div>
			</>
		);
	}

	renderCustomViews() {
		let { views, selectedViewId } = this.state;

		return (
			<>
				{!this.isEngagementViewType() && (
					<div
						className={`views-container__list__item ${this.isDefaultViewSelected() ? "views-container__list__item--selected" : ""}`}
						onClick={this.onDefaultViewSelected}
					>
						Default
					</div>
				)}
				{views.map(view => {
					return (
						<div
							className={`views-container__list__item ${selectedViewId === view.id ? "views-container__list__item--selected" : ""}`}
							onClick={event => this.onViewSelected({ event, view })}
							key={view.name + "-" + view.id}
						>
							<div>{view.name}</div>
							<div className="views-container__list__item__action" onClick={event => this.onViewEditSelected({ event, view })}>
								<Icon.Edit2 size={20} />
							</div>
						</div>
					);
				})}
			</>
		);
	}

	renderViewChangedOptions() {
		let { isViewChanged, savingView, overwritingView } = this.state;

		if (!isViewChanged) {
			return null;
		}

		return (
			<div className="views-container__unsaved">
				<div className="views-container__unsaved__blurb">You have unsaved changes to the filters.</div>
				<div className="views-container__unsaved__actions">
					<div className="btn " onClick={this.onCreateNewView}>
						{!savingView ? "Create new view" : "Saving ..."}
					</div>
					{!this.isDefaultViewSelected() && (
						<div className="btn" onClick={this.onOverwriteCurrentView}>
							{!overwritingView ? "Overwrite current view" : "Saving ..."}
						</div>
					)}
				</div>
			</div>
		);
	}

	renderAdditionalFilters() {
		let { showInactive } = this.state;
		return <Checkbox name="inactive" title="Show Inactive" checked={showInactive} onChange={this.toggleShowInactive} />;
	}

	renderViews() {
		let { showViews, minimizeViews } = this.state;

		if (!showViews) {
			return null;
		}

		return (
			<div className="views">
				<Box className="views-container" pose={minimizeViews ? "hidden" : "visible"}>
					{!minimizeViews && (
						<>
							<div className="views-container__title">Views</div>
							{this.renderViewChangedOptions()}
							<div className="views-container__list">
								{this.renderDefaultViews()}
								{this.renderCustomViews()}
							</div>
						</>
					)}
				</Box>
				<div className="views-container__arrow" onClick={this.onToggleViews}>
					{minimizeViews ? <Icon.List size={18} /> : <Icon.ChevronLeft size={18} />}
				</div>
			</div>
		);
	}

	viewHasColumn(columnId) {
		let { columns } = this.state;

		if (_.isEmpty(columns)) {
			return true;
		}

		return columns[columnId];
	}

	render() {
		let { showViewModal, selectedViewId } = this.state;

		return (
			<>
				{this.renderViews()}
				<ViewModal viewId={selectedViewId} show={showViewModal} onClose={this.onViewModalClose} onSave={this.onViewSave} onDelete={this.onViewDelete} />
			</>
		);
	}
}

export default ViewList;
