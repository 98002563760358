// External Libraries
import React from "react";
import DatePicker from "react-datepicker";
import * as Icon from "react-feather";
import Modal from "react-responsive-modal";
import { toast as alert } from "react-toastify";

// Constants
import CLIENTS from "../../../constants/Clients";

// Services
import UserService from "../../../services/UserService";
import UtilityService from "../../../services/UtilityService";
import ClientService from "../../../services/ClientService";

// Scenes
import Tasks from "../../tasks/Tasks";
import Notes from "../../notes/Notes";
import Engagements from "../../workflow/Engagements";
import Contacts from "../Contacts/Contacts";
import Tools from "../Tools/Tools";
import Connections from "../Connections/Connections";

// Common Components
import TextFieldInput from "../../common/text_field_input/TextFieldInput";
import TextAreaField from "../../common/text_area_field/TextAreaField";
import Checkbox from "../../common/checkbox/Checkbox";
import SearchableDropdown from "../../common/searchable_dropdown/SearchableDropdown";
import WComponent from "../../common/WComponent";
import Dropdown from "../../common/dropdown/Dropdown";

// Styles
import "./clientDetails.css";

class ClientDetails extends WComponent {
	constructor() {
		super();
		this.state = {
			search: "",
			loading: false,
			type: "corporate",

			teamId: 0,
			partnerId: 0,
			name: "",
			medium: "",
			yearEnd: "",
			address: "",
			notes: "",
			craBusinessNumber: "",
			isHst: false,
			isPayroll: false,
			isEht: false,
			isWsib: false,
			faxNumber: "",
			operatingName: "",

			partners: [],
			users: [],
			engagements: [],

			currentView: "details",
			showModal: false,
			showDeleteModal: false,

			statuses: CLIENTS.statuses,

			managerId: 0,
			seniorManagerId: 0,
			bookkeeperManagerId: 0,

			customFields: [],

			email: "",
			description: "",

			selectedTeamName: "",
			selectedPartnerName: "",
			selectedSeniorManagerName: "",
			selectedManagerName: "",
			selectedBookkeeperManagerName: "",

			clientGroupId: 0,
			selectedClientGroupName: ""
		};
	}

	componentDidMount = () => {
		this.resetComponent();
	};

	componentDidUpdate = prevProps => {
		let { clientId } = this.props.match.params;

		if (prevProps.match.params.clientId !== clientId) {
			this.resetComponent();
		}
	};

	resetComponent = async () => {
		let { clientType, clientId } = this.props.match.params;

		await this.update({
			type: clientType,
			clientId: clientId,
			loading: true
		});

		// Call react-alert to notify client that it is loading
		alert.info("Loading...");

		if (clientId !== "new") {
			await this.fetchClient();
		}

		await this.fetchPartners();
		await this.fetchUsers();
		await this.fetchCustomFields();

		await this.update({ loading: false });
	};

	fetchPartners = async () => {
		let partners = await UserService.fetchPartners("Unassigned");

		this.update({
			partners: partners
		});
	};

	fetchUsers = async () => {
		let users = await UserService.fetchUsers({});
		users.unshift({ key: 0, value: "Unassigned" });
		this.update({
			users: users
		});
	};

	fetchClient = async () => {
		let { clientId } = this.state;

		try {
			let response = await ClientService.getClient({ clientId });

			let client = response.data.client;
			let engagements = response.data.engagements;

			// React Date Picker does not like 0000-00-00 as the date
			if (client.year_end === "0000-00-00") {
				client.year_end = "2000-01-01";
			}

			let selectedPartnerName = "";
			let selectedSeniorManagerName = "";
			let selectedManagerName = "";
			let selectedBookkeeperManagerName = "";
			let selectedClientGroupName = "";

			if (UtilityService.isNotNullish(client.User)) {
				selectedPartnerName = `${client.User.first_name} ${client.User.last_name}`;
			}

			if (UtilityService.isNotNullish(client.senior_manager)) {
				selectedSeniorManagerName = `${client.senior_manager.first_name} ${client.senior_manager.last_name}`;
			}

			if (UtilityService.isNotNullish(client.manager)) {
				selectedManagerName = `${client.manager.first_name} ${client.manager.last_name}`;
			}

			if (UtilityService.isNotNullish(client.bookkeeper_manager)) {
				selectedBookkeeperManagerName = `${client.bookkeeper_manager.first_name} ${client.bookkeeper_manager.last_name}`;
			}

			if (UtilityService.isNotNullish(client.client_group_id)) {
				selectedClientGroupName = client.client_group.name;
			}

			this.update({
				teamId: client.team_id,
				clientGroupId: client.client_group_id,
				partnerId: client.partner_id,
				managerId: client.manager_id,
				seniorManagerId: client.senior_manager_id,
				bookkeeperManagerId: client.bookkeeper_manager_id,
				type: client.type,
				name: client.name,
				medium: client.medium,
				engagements: engagements,
				yearEnd: client.year_end,
				notes: client.notes,
				address: client.address,
				craBusinessNumber: client.cra_business_number,
				isHst: client.is_hst,
				isPayroll: client.is_payroll,
				isEht: client.is_eht,
				isWsib: client.is_wsib,
				faxNumber: client.fax_number,
				operatingName: client.operating_name,
				status: client.status,
				email: client.email,
				description: client.description,

				selectedPartnerName,
				selectedSeniorManagerName,
				selectedManagerName,
				selectedBookkeeperManagerName,
				selectedClientGroupName
			});

			if (client.team) {
				this.update({
					selectedTeamName: client.team.name
				});
			}
		} catch (error) {
			console.log(error);
		}
	};

	updateClient = async () => {
		let {
			clientId,
			clientGroupId,
			partnerId,
			managerId,
			name,
			yearEnd,
			address,
			notes,
			craBusinessNumber,
			isHst,
			isPayroll,
			isEht,
			isWsib,
			faxNumber,
			operatingName,
			status,
			customFields,
			email,
			description,
			loading,
			teamId,
			seniorManagerId,
			bookkeeperManagerId
		} = this.state;

		let { alert } = this.props;

		if (!this.isValid()) {
			// Call react-alert saying there are missing fields
			alert.error("Missing fields!");
			return;
		}

		if (loading) {
			// Call react-alert saying the client is being updated
			alert.info("Updating...");
			return;
		}

		this.update({
			loading: true
		});

		try {
			await ClientService.updateClient({
				clientGroupId,
				teamId,
				seniorManagerId,
				bookkeeperManagerId,
				clientId,
				partnerId,
				managerId,
				name,
				yearEnd,
				address,
				notes,
				craBusinessNumber,
				isHst,
				isPayroll,
				isEht,
				isWsib,
				faxNumber,
				operatingName,
				status,
				customFields,
				email,
				description
			});

			// Call react-alert saying the client was successfully updated
			alert.success("Updated Successfully!");
		} catch (error) {
			console.log(error);
			// Call react-alert saying there was an error trying to update the client
			alert.error("Update Error!");
		}

		this.update({
			loading: false
		});
	};

	createClient = async () => {
		let {
			clientGroupId,
			partnerId,
			managerId,
			name,
			yearEnd,
			address,
			notes,
			craBusinessNumber,
			isHst,
			isPayroll,
			isEht,
			isWsib,
			faxNumber,
			operatingName,
			customFields,
			email,
			description,
			loading,
			type,
			teamId,
			seniorManagerId,
			bookkeeperManagerId
		} = this.state;

		let { alert, history } = this.props;

		if (!this.isValid()) {
			// Call react-alert saying there are missing fields
			alert.error("Missing fields!");
			return;
		}

		if (loading) {
			// Call react-alert saying the client is being updated<<
			alert.info("Updating...");
			return;
		}

		this.update({
			loading: true
		});

		try {
			let response = await ClientService.createClient({
				type,
				clientGroupId,
				partnerId,
				managerId,
				name,
				yearEnd,
				address,
				notes,
				craBusinessNumber,
				isHst,
				isPayroll,
				isEht,
				isWsib,
				faxNumber,
				operatingName,
				teamId,
				seniorManagerId,
				bookkeeperManagerId,
				customFields,
				email,
				description
			});

			// Call react-alert saying that the client was updated successfully
			alert.success("Updated Successfully!");
			history.push(`/clients/${type}/${response.data.id}`);
		} catch (error) {
			// Call react-alert saying there is some kind of error trying to update the client
			alert.error("Update Error!");
			console.log(error);
		}

		this.update({
			loading: false
		});
	};

	onDeleteClient = async () => {
		let { clientId } = this.state;
		let { alert } = this.props;

		this.update({
			loading: true
		});

		let success = await ClientService.deleteClient({ clientId });

		if (success) {
			alert.success("Deleted Successfully!");
		} else {
			alert.error("Delete Error!");
		}

		this.update({
			loading: false
		});
	};

	onChange = e => {
		let target = e.target;
		let value = target.type === "checkbox" ? target.checked : target.value;
		this.update({
			[target.name]: value
		});
	};

	onClientGroupSelect = item => {
		this.update({
			clientGroupId: item.id,
			selectedClientGroupName: item.name
		});
	};

	onPartnerSelect = item => {
		this.update({
			partnerId: item.id,
			selectedPartnerName: item.name
		});
	};

	onManagerSelect = item => {
		this.update({
			managerId: item.id,
			selectedManagerName: item.name
		});
	};

	onSeniorManagerSelect = item => {
		this.update({
			seniorManagerId: item.id,
			selectedSeniorManagerName: item.name
		});
	};

	onBookkeeperManagerSelect = item => {
		this.update({
			bookkeeperManagerId: item.id,
			selectedBookkeeperManagerName: item.name
		});
	};

	onStatusSelect = item => {
		this.update({
			status: item.key
		});
	};

	onYearEndSelect = date => {
		this.update({
			yearEnd: date
		});
	};

	onSelectTeam = async team => {
		await this.update({
			teamId: team.id,
			selectedTeamName: team.name
		});
	};

	onBack = () => {
		let { history } = this.props;
		history.goBack();
	};

	isNewClient = () => {
		let { clientId } = this.state;
		return clientId === "new";
	};

	isDisabled = () => {
		let { loading } = this.state;
		return !this.isValid() || loading;
	};

	isValid = () => {
		let { name } = this.state;
		return name.length > 0;
	};

	onConfirmModalOpen = () => {
		this.update({
			showDeleteModal: true
		});
	};

	onCloseDeleteModal = ({ confirmed }) => {
		this.update({
			showDeleteModal: false
		});

		let { clientType } = this.props.match.params;
		let { history } = this.props;

		if (confirmed === true) {
			this.onDeleteClient();
			history.push({
				pathname: `/clients/${clientType}`,
				state: { resetComponent: true }
			});
		}
	};

	switchView = ({ view }) => {
		this.update({
			currentView: view
		});
	};

	onShowModal = () => {
		this.update({
			showModal: true
		});
	};

	onCloseModal = confirmed => {
		if (confirmed) {
			if (this.isNewClient()) {
				this.createClient();
			} else {
				this.updateClient();
			}
		}

		this.update({
			showModal: false
		});
	};

	fetchCustomFields = async () => {
		let { clientId } = this.state;

		let customFields = await ClientService.fetchCustomFields({ clientId });

		this.update({
			customFields
		});
	};

	onCustomFieldChange = ({ customField, newValue }) => {
		let { customFields } = this.state;

		customFields = customFields.slice();

		let [updatedCustomField] = customFields.filter(cf => cf.id === customField.id);

		updatedCustomField.value = newValue;

		this.update({
			customFields
		});
	};

	renderCustomFields = () => {
		let { customFields } = this.state;

		return customFields.map((cf, index) => {
			if (cf.type === CLIENTS.customFieldTypes.string) {
				return (
					<TextFieldInput
						title={cf.name}
						name={cf.field}
						onChange={event => this.onCustomFieldChange({ customField: cf, newValue: event.target.value })}
						value={cf.value || ""}
						autoComplete="off"
						key={cf.name}
					/>
				);
			} else if (cf.type === CLIENTS.customFieldTypes.list) {
				return (
					<SearchableDropdown
						title={cf.name}
						type="customFieldList"
						customFieldId={cf.id}
						selectedName={cf.value}
						onSelect={item => this.onCustomFieldChange({ customField: cf, newValue: item.name })}
						showAll
						key={cf.name}
					/>
				);
			}

			return null;
		});
	};

	render() {
		let {
			// Display Items
			currentView,
			showModal,
			showDeleteModal,
			selectedTeamName,
			selectedPartnerName,
			selectedManagerName,
			selectedSeniorManagerName,
			selectedBookkeeperManagerName,
			selectedClientGroupName,

			// Client Data
			name,
			type,
			clientId,
			craBusinessNumber,
			operatingName,
			status,
			yearEnd,
			statuses,
			isHst,
			isPayroll,
			isEht,
			isWsib,
			email,
			description,
			address

			// TODO - Remove?
			// partners,
			// partnerId,
			// seniorManagerId,
			// users,
			// managerId
		} = this.state;

		return (
			<>
				<div className="client-details-container">
					<div className="container-header">
						<div className="container-header-title ">
							<h3 className="container-header-title__sub">Clients - {CLIENTS.clientTypes[type].value}</h3>
							<div className="container-header-title__name">
								<div className="container-header-title__name__back" onClick={this.onBack}>
									<Icon.ArrowLeftCircle size={32} />
								</div>
								{this.isNewClient() ? "New Client" : name}
							</div>
						</div>
					</div>

					{clientId !== "new" && (
						<div className="toggle-container client-toggle-container">
							<div className="toggle">
								<button className={currentView === "details" ? "toggle--active" : ""} onClick={() => this.switchView({ view: "details" })}>
									Details
								</button>
							</div>
							<div className="toggle">
								<button className={currentView === "contacts" ? "toggle--active" : ""} onClick={() => this.switchView({ view: "contacts" })}>
									Contacts
								</button>
							</div>
							<div className="toggle">
								<button
									className={currentView === "engagements" ? "toggle--active" : ""}
									onClick={() => this.switchView({ view: "engagements" })}
								>
									Engagements
								</button>
							</div>
							<div className="toggle">
								<button className={currentView === "tasks" ? "toggle--active" : ""} onClick={() => this.switchView({ view: "tasks" })}>
									Tasks
								</button>
							</div>
							<div className="toggle">
								<button className={currentView === "notes" ? "toggle--active" : ""} onClick={() => this.switchView({ view: "notes" })}>
									Notes
								</button>
							</div>
							<div className="toggle">
								<button className={currentView === "tools" ? "toggle--active" : ""} onClick={() => this.switchView({ view: "tools" })}>
									Tools
								</button>
							</div>
							<div className="toggle">
								<button
									className={currentView === "connections" ? "toggle--active" : ""}
									onClick={() => this.switchView({ view: "connections" })}
								>
									Connections
								</button>
							</div>

							{/* <div className="toggle">
                            <button className={currentView === "documents" ? "toggle--active" : ""} onClick={() => this.switchView("documents")}>Document Requests</button>
                        </div> */}

							{/* <div className="toggle">
								<button
									className={currentView === "task_templates" ? "toggle--active" : ""}
									onClick={() => this.switchView({ view: "task_templates" })}
								>
									Task Templates
								</button>
							</div> */}

							{/* <div className="toggle">
								<button className={currentView === "schedules" ? "toggle--active" : ""} onClick={() => this.switchView({ view: "schedules" })}>
									Schedules
								</button>
							</div> */}
						</div>
					)}

					{currentView === "details" && (
						<div className="details">
							<div className="details__general">
								<div className="details__general__title">General</div>
								{type === CLIENTS.clientTypes.corporate.id && (
									<TextFieldInput
										title="CRA Business Number"
										name="craBusinessNumber"
										onChange={this.onChange}
										value={craBusinessNumber || ""}
										autoComplete="off"
									/>
								)}

								<TextFieldInput
									title="Operating Name"
									name="operatingName"
									onChange={this.onChange}
									value={operatingName || ""}
									autoComplete="off"
								/>

								<TextFieldInput title="Name" name="name" onChange={this.onChange} value={name || ""} autoComplete="off" />

								<SearchableDropdown
									title="Client Group"
									type={"clientGroups"}
									selectedName={selectedClientGroupName}
									onSelect={this.onClientGroupSelect}
									showAll
								/>

								<SearchableDropdown title="Team" onSelect={this.onSelectTeam} type={"teams"} selectedName={selectedTeamName} showAll />

								<SearchableDropdown
									title="Partner"
									type={"partners"}
									selectedName={selectedPartnerName}
									onSelect={this.onPartnerSelect}
									showAll
								/>
								<SearchableDropdown
									title="Senior Manager"
									type={"users"}
									selectedName={selectedSeniorManagerName}
									onSelect={this.onSeniorManagerSelect}
									showAll
								/>

								<SearchableDropdown title="Manager" type={"users"} selectedName={selectedManagerName} onSelect={this.onManagerSelect} showAll />

								{type === CLIENTS.clientTypes.corporate.id && (
									<SearchableDropdown
										title="Bookkeeper Manager"
										type={"users"}
										selectedName={selectedBookkeeperManagerName}
										onSelect={this.onBookkeeperManagerSelect}
									/>
								)}

								<Dropdown title="Status" items={statuses} selected={status} onSelect={this.onStatusSelect} alternate={true} />

								<div className="client-details-datepicker-field-container">
									<h3 className="client-details-datepicker-title">Year End</h3>
									<DatePicker
										className="client-details-datepicker"
										selected={yearEnd ? new Date(yearEnd) : undefined}
										onChange={this.onYearEndSelect}
										timeFormat="HH:mm"
										timeIntervals={15}
										dateFormat="MMMM d, yyyy"
										timeCaption="time"
										autoComplete="off"
									/>
								</div>

								<Checkbox title="HST" name="isHst" checked={isHst} onChange={this.onChange} />

								{type === CLIENTS.clientTypes.corporate.id && (
									<>
										<Checkbox title="Payroll" name="isPayroll" checked={isPayroll} onChange={this.onChange} />
										<Checkbox title="EHT" name="isEht" checked={isEht} onChange={this.onChange} />
										<Checkbox title="WSIB" name="isWsib" checked={isWsib} onChange={this.onChange} />
									</>
								)}

								<TextFieldInput title="Email" name="email" onChange={this.onChange} value={email || ""} autoComplete="off" />
								<TextFieldInput title="Description" name="description" onChange={this.onChange} value={description || ""} autoComplete="off" />

								<TextAreaField title="Address" type="text" name="address" value={address || ""} autoComplete="off" onChange={this.onChange} />
							</div>
							<div className="details__more">
								<div className="details__more__title">More</div>
								{this.renderCustomFields()}
							</div>
							<div className="details__actions">
								<div className={"btn " + (this.isDisabled() ? "disable" : "")} onClick={this.onShowModal}>
									{this.isNewClient() ? "Create" : "Update"}
								</div>
								<div className="btn btn--icon" onClick={() => this.onConfirmModalOpen()}>
									<Icon.Trash2 size={22} />
								</div>
							</div>
						</div>
					)}
				</div>

				{currentView === "engagements" && clientId !== "new" && parseInt(clientId) > 0 && (
					<Engagements title={`Engagements for ${name}`} clientId={clientId} showViews={false} showFilters={false} {...this.props} />
				)}

				{currentView === "contacts" && parseInt(clientId) > 0 && <Contacts title={"Contacts"} clientId={clientId} {...this.props} />}

				{/* {currentView === "documents" && parseInt(this.state.clientId) > 0 && (<DocumentRequests
                    title={"Document Requests"}
                    clientId={this.state.clientId}
                    {...this.props}
                />)} */}

				{currentView === "notes" && parseInt(clientId) > 0 && <Notes title="Notes" pinnedOnly={true} clientId={clientId} {...this.props} />}

				{currentView === "tasks" && parseInt(clientId) > 0 && (
					<Tasks title="Tasks" clientId={clientId} showViews={false} showFilters={false} {...this.props} />
				)}

				{currentView === "tools" && <Tools clientId={clientId} {...this.props} />}
				{currentView === "connections" && <Connections clientId={clientId} {...this.props} />}

				{/* {currentView === "task_templates" && parseInt(clientId) > 0 && (
					<TaskTemplates raw={true} title="Task Templates" clientId={clientId} clientName={name} {...this.props} />
				)} */}

				{/* {currentView === "schedules" && parseInt(clientId) > 0 && <Schedules title="Schedules" clientId={clientId} {...this.props} />} */}

				<Modal open={showModal} onClose={() => this.onCloseModal({ confirmed: false })} center>
					<div className="modal-title">{this.isNewClient() ? "Create Client?" : "Update Client?"}</div>
					<div className="modal-content">Are you sure you want to {this.isNewClient() ? "create" : "update"} this client?</div>
					<div className="btn-group">
						<div onClick={() => this.onCloseModal({ confirmed: true })} className="btn">
							Yes
						</div>
						<div onClick={() => this.onCloseModal({ confirmed: false })} className="btn">
							No
						</div>
					</div>
				</Modal>

				<Modal open={showDeleteModal} onClose={this.onCloseDeleteModal} center>
					<div className="modal-title">Delete Client?</div>
					<div className="modal-content">Are you sure you want to remove this client?</div>
					<div className="btn-group">
						<div onClick={() => this.onCloseDeleteModal({ confirmed: true })} className="engagement-button">
							Yes
						</div>
						<div onClick={() => this.onCloseDeleteModal({ confirmed: false })} className="engagement-button">
							No
						</div>
					</div>
				</Modal>
			</>
		);
	}
}

export default ClientDetails;
