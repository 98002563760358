import React, { Component } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Login from "./components/auth/Login.js";
import PrivateRoute from "./components/common/PrivateRoute.js";
import Main from "./components/layout/Main.js";
import "react-responsive-modal/styles.css";
import Toast from "./components/common/toast/toast.js";

import "./App.css";

class App extends Component {
	render() {
		return (
			<Router>
				<div className="App">
					<Toast />
					<Switch>
						<Route exact path="/login" component={Login} />
						<PrivateRoute component={Main} path="/" />
					</Switch>
				</div>
			</Router>
		);
	}
}

export default App;
