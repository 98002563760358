import React from "react";
import * as Icon from "react-feather";
import moment from "moment";

import UserService from "../../services/UserService";

import WComponent from "../common/WComponent";
import TeamModal from "./TeamModal";
import Loader from "../common/Loader";

class Teams extends WComponent {
	constructor() {
		super();
		this.state = {
			search: "",
			loading: false,

			teams: [],
			sortedField: "name",
			desc: true,

			showTeamModal: false,
			teamId: "new"
		};
	}

	componentDidMount() {
		this.resetComponent();
	}

	async resetComponent() {
		await this.update({
			loading: true
		});

		await this.fetchTeams();

		this.update({
			loading: false
		});
	}

	async fetchTeams() {
		let { search } = this.state;

		let teams = await UserService.fetchTeams({ search });

		this.update({
			teams
		});
	}

	switchView(view) {
		this.update({
			currentView: view
		});
	}

	onNewTeam = () => {
		this.update({
			teamId: "new",
			showTeamModal: true
		});
	};

	onTeamModalClose = async () => {
		await this.update({
			showTeamModal: false
		});

		await this.fetchTeams();
	};

	sortBy() {}

	renderSortIcon(field) {
		return this.state.sortedField === field && (this.state.desc ? <Icon.ChevronUp size={14} /> : <Icon.ChevronDown size={14} />);
	}

	onSearch = async event => {
		await this.update({ search: event.target.value });
		await this.fetchTeams();
	};

	onOpenTeam = async teamId => {
		await this.update({
			showTeamModal: true,
			teamId
		});
	};

	render() {
		let { teams, loading, search, teamId, showTeamModal } = this.state;

		return (
			<>
				<div className="container-actions">
					<button className="container-add" type="button" onClick={this.onNewTeam}>
						<Icon.Plus size={18} />
					</button>
					<input
						className="container-search"
						type="search"
						name="search"
						placeholder="Search..."
						autoComplete="off"
						value={search}
						onChange={this.onSearch}
					/>
				</div>

				{loading && <Loader />}

				{teams.length > 0 && !loading && (
					<div className="list-container">
						<div className="list-header list-item">
							<div onClick={() => this.sortBy("name")} className="list-column">
								Name {this.renderSortIcon("name")}
							</div>
							<div onClick={() => this.sortBy("description")} className="list-column list-column--xxl">
								Description {this.renderSortIcon("description")}
							</div>
							<div onClick={() => this.sortBy("date")} className="list-column">
								Creation Date {this.renderSortIcon("date")}
							</div>
						</div>

						{teams.map((team, index) => {
							let odd = index % 2 === 0;

							return (
								<div className={`list-item ${odd ? "list-item--stripe" : ""}`} key={index}>
									<div className="list-column" onClick={() => this.onOpenTeam(team.id)}>
										{team.name}
									</div>
									<div className="list-column list-column--xxl">{team.description}</div>
									<div className="list-column">{moment(team.created_at).format("LL")}</div>
								</div>
							);
						})}
					</div>
				)}
				<TeamModal show={showTeamModal} teamId={teamId} onClose={this.onTeamModalClose} {...this.props} />
			</>
		);
	}
}

export default Teams;
