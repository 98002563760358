// External Libraries
import React from "react";
import DatePicker from "react-datepicker";

import { toast as alert } from "react-toastify";

// Services
import ReconciliationService from "../../../../services/ReconciliationService";

// Common Components
import WComponent from "../../../common/WComponent";
import ConfigurationService from "../../../../services/ConfigurationService";
import Loader from "../../../common/Loader";

// import ReconSummary from "./ReconciliationSummary";
// import ReconTable from "./ReconciliationTable";

// Styling
// import "./hstReconciliation-tool.css";

class HstReconciliationTool extends WComponent {
	constructor(props) {
		super(props);

		this.state = {
			// Input State
			endDate: new Date(),

			// Data State
			reconciliationData: [],
			filteredReconciliationData: [],
			currentYearSummary: [],

			// UI State
			loading: false,
			isReconciliationComplete: false
		};
	}

	onReconcileClicked = async e => {
		let { clientId } = this.props;
		let { endDate } = this.state;

		e.preventDefault();

		await this.update({
			loading: true
		});

		await ReconciliationService.downloadGSTSummaries({ clientId, endDate });
		await ReconciliationService.downloadGSTSummariesQuickMethod({ clientId, endDate });

		await this.update({
			loading: false
		});
	};

	onendDateChange = async endDate => {
		await this.update({ endDate });
	};

	render() {
		let { endDate, loading } = this.state;

		return (
			<>
				<div className="container">
					<div className="container-header-title">
						<h1 className="container-header-title__name">HST Reconciliation Tool</h1>
					</div>

					<div className="eng-modal-datepicker-container">
						<span className="eng-modal-datepicker-title">Year End</span>
						<DatePicker
							className="eng-modal-datepicker"
							selected={endDate !== "" ? new Date(endDate) : new Date()}
							onChange={this.onendDateChange}
							autoComplete="off"
							dateFormat="MMMM d, yyyy"
						/>

						<div className="btn" onClick={this.onReconcileClicked}>
							Reconcile
						</div>
					</div>

					{loading && (
						<div className="container-loader">
							<Loader />
						</div>
					)}
				</div>
			</>
		);
	}
}

export default HstReconciliationTool;
