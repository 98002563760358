export default `swagger: "2.0"
info:
  version: "0.0.1"
  title: document-portal-api
# during dev, should point to your local machine
host: localhost:4000
# basePath prefixes all resource paths
basePath: /api
#
schemes:
  # tip: remove http to make production-grade
  - http
# format of bodies a client can send (Content-Type)
consumes:
  - application/json
# format of the responses to the client (Accepts)
produces:
  - application/json

securityDefinitions:
  authorization:
    x-authorize: security/auth.js
    scopes:
      default: Default Scope
  
  clientPortalAuthorization: 
    x-authorize: security/clientPortalAuth.js
    scopes:
      default: Default Scope

security:
  - authorization: [ default ]

tags:
  - name: user
    description: User namespace
  - name: company
    description: Company namespace
  - name: client
    description: Client Namespace
  - name: task
    description: Task Namespace
  - name: engagement
    description: Engagement Namespace
  - name: contact
    description: Contact Namespace
  - name: document_request
    description: Document Request Namespace
  - name: note
    description: Note Namespace
  - name: event
    description: Event Namespace
  - name: field
    description: Field Namespace
  - name: team
    description: Team Namespace
  - name: role
    description: Role Namespace
  - name: task_template
    description: Task Template Namespace
  - name: schedule
    description: Schedule Namespace
  - name: view
    description: View Namespace
  - name: invoice
    description: View Namespace
  - name: subscription
    description: View Namespace
  - name: customer
    description: Customer Namespace
  - name: service
    description: Service Namespace
  - name: integration
    description: Integration Namespace
  - name: project
    description: Project Namespace
  - name: client_portal
    description: Client Portal Namespace
  - name: plaid_client
    description: Plaid Client Namespace
  - name: tools
    description: Tools Namespace
  - name: utility
    description: Utilities Namespace

paths:

  /user/login:
    post:
      description: Attempt to log the user in
      tags: [ user ]
      operationId: login

      security: []

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /user/logout:
    post:
      description: Attempt to logout the user
      tags: [ user ]
      operationId: logout

      security: []

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /user/create:
    post:
      description: Create a user
      tags: [ user ]
      operationId: create

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /user/{userId}/update:
    post:
      description: Update a user
      tags: [ user ]
      operationId: update

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /user/list:
    get:
      description: Fetches a list of users.
      tags: [ user ]
      operationId: list

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /user/search:
    post:
      description: Searches a list of users by their first name or last name
      tags: [ user ]
      operationId: search

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /user/{userId}:
    get:
      description: Fetches a single user.
      tags: [ user ]
      operationId: get

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /company/create:
    post:
      description: Create a company
      tags: [ company ]
      operationId: create

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /company/list:
    get:
      description: Fetch all companies
      tags: [ company ]
      operationId: list

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /company/{companyId}/update:
    post:
      description: Update a company
      tags: [ company ]
      operationId: update

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /company/{companyId}:
    get:
      description: Get a company
      tags: [ company ]
      operationId: get

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /engagement/count:
    get:
      description: Counts all the engagements for a specific partner
      tags: [ engagement ]
      operationId: count

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /engagement/create:
    post:
      description: Create an engagement
      tags: [ engagement ]
      operationId: create

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /engagement/multi_create:
    post:
      description: Create multiple engagements for different clients and flows
      tags: [ engagement ]
      operationId: multiCreate

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /engagement/list:
    get:
      description: Fetch all engagements
      tags: [ engagement ]
      operationId: list

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /engagement/export:
    post:
      description: Retrieve a list of engagements
      tags: [ engagement ]
      operationId: exportList

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /engagement/{engagementId}/update:
    post:
      description: Update an engagement
      tags: [ engagement ]
      operationId: update

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /engagement/{engagementId}:
    get:
      description: Get an engagement
      tags: [ engagement ]
      operationId: get

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /engagement/{engagementId}/delete:
    post:
      description: Delete an engagement
      tags: [ engagement ]
      operationId: delete

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /task/create:
    post:
      description: Create a task
      tags: [ task ]
      operationId: create

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /task/list:
    get:
      description: Fetch all tasks
      tags: [ task ]
      operationId: list

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /task/{taskId}/update:
    post:
      description: Update a task
      tags: [ task ]
      operationId: update

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /task/{taskId}:
    get:
      description: Get a task
      tags: [ task ]
      operationId: get

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /task/{taskId}/mark_read:
    get:
      description: Sets the task as read
      tags: [ task ]
      operationId: markRead

      security: []

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /client/create:
    post:
      description: Create a client
      tags: [ client ]
      operationId: create

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /client/search:
    post:
      description: Create a client
      tags: [ client ]
      operationId: search

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /client/list:
    get:
      description: Fetch all clients
      tags: [ client ]
      operationId: list

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"
            
  /client/summary:
    get:
      description: Get a full client breakdown for a company
      tags: [ client ]
      operationId: summary

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /client/export:
    post:
      description: Retrieve a CSV file of the current filtered clients
      tags: [ client ]
      operationId: exportList

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /client/{clientId}/update:
    post:
      description: Update a client
      tags: [ client ]
      operationId: update

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /client/{clientId}:
    get:
      description: Get a client
      tags: [ client ]
      operationId: get

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /client/{clientId}/remove_contact:
    post:
      description: Delete an contact from a client
      tags: [ client ]
      operationId: remove_contact

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"
  
  /client/{clientId}/delete:
    post:
      description: Delete an client
      tags: [ client ]
      operationId: delete

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /client/{clientId}/custom_field:
    get:
      description: Get all the custom fields for a Client
      tags: [ client ]
      operationId: getCustomFields

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /client_portal/{clientId}:
    get:
      description: Get a client
      tags: [ client_portal ]
      operationId: get

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /client/group/list:
    get:
      description: Fetches a list of client groups
      tags: [ client ]
      operationId: listGroups

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /client/group/{clientGroupId}/update:
    post:
      description: Updates a specific client group
      tags: [ client ]
      operationId: updateClientGroup

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /client/group/{clientGroupId}:
    get:
      description: Fetches a specified client group
      tags: [ client ]
      operationId: getClientGroup

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /client/group/create:
    post:
      description: Creates a new client group
      tags: [ client ]
      operationId: createClientGroup

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /contact/create:
    post:
      description: Create a contact
      tags: [ contact ]
      operationId: create

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /contact/list:
    get:
      description: Fetch all contacts
      tags: [ contact ]
      operationId: list

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /contact/search:
    post:
      description: Search for contacts
      tags: [ contact ]
      operationId: search

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /contact/{contactId}/update:
    post:
      description: Update a contact
      tags: [ contact ]
      operationId: update

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /contact/{contactId}/assign:
    post:
      description: Assign contact to a client
      tags: [ contact ]
      operationId: assign

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /contact/{contactId}:
    get:
      description: Get a contact
      tags: [ contact ]
      operationId: get

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"


  /document_request/create:
      post:
        description: Creates a new document request
        tags: [ document_request ]
        operationId: create
        security: [
            authorization: [
              default
            ]
        ]
        responses:
          "200":
            description: Success
            schema:
              $ref: "#/definitions/Response"
          default:
            description: Error
            schema:
              $ref: "#/definitions/Response"


  /document_request/upload:
    post:
      description: Handles client uploads
      tags: [ document_request ]
      operationId: upload

      security: []

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"
            

  /document_request/list:
    get:
      description: Lists all the document requests for a particular client
      tags: [ document_request ]
      operationId: list

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /document_request/download/{shortId}:
    get:
      description: Creates a new document request
      tags: [ document_request ]
      operationId: download

      security: [
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /document_request/{shortId}:
    get:
      description: Fetches a document request
      tags: [ document_request ]
      operationId: get

      security: []

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /flow/list:
    get:
      description: Fetches all the flows for a company
      tags: [ flow ]
      operationId: list

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /flow/state/list:
    get:
      description: Fetches all the flow states for a flow
      tags: [ flow ]
      operationId: listStates

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /flow/{flowId}:
    get:
      description: Fetches a single flow
      tags: [ flow ]
      operationId: get

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /note/{noteId}/update:
    post:
      description: Update a note
      tags: [ note ]
      operationId: update

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /note/create:
    post:
      description: Create a note
      tags: [ note ]
      operationId: create

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /note/list:
    get:
      description: Fetch all notes
      tags: [ note ]
      operationId: list

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /note/{noteId}:
    get:
      description: Get a note
      tags: [ note ]
      operationId: get

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"



  /event/list:
    get:
      description: Fetch all events for a client or engagement
      tags: [ event ]
      operationId: list

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /field/{fieldId}/update:
    post:
      description: Update a field
      tags: [ field ]
      operationId: update

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /field/create:
    post:
      description: Create a field
      tags: [ field ]
      operationId: create

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /field/list:
    get:
      description: Fetch all fields
      tags: [ field ]
      operationId: list

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /field/{fieldId}:
    get:
      description: Get a field
      tags: [ field ]
      operationId: get

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /team/{teamId}/update:
    post:
      description: Update a team
      tags: [ team ]
      operationId: update

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /team/create:
    post:
      description: Create a team
      tags: [ team ]
      operationId: create

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /team/list:
    get:
      description: Fetch all teams for a company
      tags: [ team ]
      operationId: list

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /team/{teamId}:
    get:
      description: Get a team
      tags: [ team ]
      operationId: get

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /role/{roleId}/update:
    post:
      description: Update a role
      tags: [ role ]
      operationId: update

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /role/create:
    post:
      description: Create a role
      tags: [ role ]
      operationId: create

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /role/list:
    get:
      description: Fetch all roles for a company
      tags: [ role ]
      operationId: list

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /role/{roleId}:
    get:
      description: Get a role
      tags: [ role ]
      operationId: get

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /task_template/{taskTemplateId}/update:
    post:
      description: Update a task template
      tags: [ task_template ]
      operationId: update

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /task_template/create:
    post:
      description: Create a task template
      tags: [ task_template ]
      operationId: create

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /task_template/list:
    get:
      description: Fetch all task templates for a client
      tags: [ task_template ]
      operationId: list

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /task_template/{taskTemplateId}:
    get:
      description: Get a task template
      tags: [ task_template ]
      operationId: get

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /schedule/{scheduleId}/update:
    post:
      description: Update a schedule
      tags: [ schedule ]
      operationId: update

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /schedule/create:
    post:
      description: Create a schedule
      tags: [ schedule ]
      operationId: create

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /schedule/list:
    get:
      description: Fetch all schedules for a client
      tags: [ schedule ]
      operationId: list

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /schedule/{scheduleId}:
    get:
      description: Get a schedule
      tags: [ schedule ]
      operationId: get

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /view/create:
    post:
      description: Create a view
      tags: [ view ]
      operationId: create

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /view/list:
    get:
      description: Fetch all views for a user
      tags: [ view ]
      operationId: list

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /view/{viewId}/update:
    post:
      description: Update a view
      tags: [ view ]
      operationId: update

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /view/{viewId}:
    get:
      description: Get a view
      tags: [ view ]
      operationId: get

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /engagement/template/create:
    post:
      description: Create an engagement template
      tags: [ engagement ]
      operationId: createTemplate

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /engagement/template/list:
    get:
      description: Fetch all engagement templates for a company
      tags: [ engagement ]
      operationId: listTemplates

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /engagement/template/{engagementTemplateId}/update:
    post:
      description: Update an engagement template
      tags: [ engagement ]
      operationId: updateTemplate

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /engagement/template/{engagementTemplateId}:
    get:
      description: Get an engagement template
      tags: [ engagement ]
      operationId: getTemplate

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /custom/invoice/create:
    post:
      description: Create an invoice
      tags: [ invoice ]
      operationId: create

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /custom/invoice/sync:
    post:
      description: Syncs a list of invoices to the necessary integrations
      tags: [ invoice ]
      operationId: sync

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /custom/invoice/list:
    get:
      description: Fetch all invoices for a client
      tags: [ invoice ]
      operationId: list

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /custom/invoice/{invoiceId}/update:
    post:
      description: Update an invoice
      tags: [ invoice ]
      operationId: update

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /custom/invoice/{invoiceId}:
    get:
      description: Get an invoice
      tags: [ invoice ]
      operationId: get

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /custom/subscription/create:
    post:
      description: Create a subscription
      tags: [ subscription ]
      operationId: create

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /custom/subscription/list:
    get:
      description: Fetch all subscriptions for a client
      tags: [ subscription ]
      operationId: list

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /custom/subscription/{subscriptionId}/update:
    post:
      description: Update an subscription
      tags: [ subscription ]
      operationId: update

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /custom/subscription/{subscriptionId}:
    get:
      description: Get a subscription
      tags: [ subscription ]
      operationId: get

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /custom/service/list:
    get:
      description: Fetch all services for a client
      tags: [ service ]
      operationId: list

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /support/create:
    post:
      description: Create a card
      tags: [ support ]
      operationId: create

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /customer/list:
    get:
      description: Lists customers of client
      tags: [ customer ]
      operationId: list

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /integration/qbo/auth:
    get:
      description: Get Consent UI Link for Intuit OAuth
      tags: [ integration ]
      operationId: qboAuth

      security: []

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /integration/qbo/redirect:
    get:
      description: Get Consent UI Link for Intuit OAuth
      tags: [ integration ]
      operationId: qboRedirect

      security: []

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /custom/project/list:
    get:
      description: Get a list of TeamWork projects
      tags: [ project ]
      operationId: list

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /engagement/recurring/{recurringEngagementId}/update:
    post:
      description: Update a recurring engagement record
      tags: [ engagement ]
      operationId: updateRecurringEngagement

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /tools/reconciliation/upload:
    post:
      description: Upload PDF and Excel docs for reconciliation
      tags: [ tools ]
      operationId: upload

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /tools/reconciliation/download:
    post:
      description: Downloads the Excel reconciliation
      tags: [ tools ]
      operationId: download

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /tools/hstReconciliation/download:
    post:
      description: Downloads the Excel reconciliation
      tags: [ tools ]
      operationId: download

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /tools/hstReconciliation/downloadQuickMethod:
    post:
      description: Downloads the Quick Method Excel reconciliation
      tags: [ tools ]
      operationId: download

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /tools/plaid/connect/create_short_link:
    get:
      description: Creates a Plaid short link that can be shared with clients
      tags: [ tools ]
      operationId: createPlaidShortLink

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /tools/plaid/connect/create_link_token:
    get:
      description: Creates a Plaid link token to start the plaid connection process
      tags: [ tools ]
      operationId: createPlaidLinkToken

      security: []

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /tools/plaid/connect/complete_setup:
    post:
      description: Completes the setup process for a client to connect their bank accounts to workflow through Plaid
      tags: [ tools ]
      operationId: completePlaidSetup

      security: []

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /tools/plaid/core/links:
    get:
      description: Fetches all the connection plaid links for a client
      tags: [ tools ]
      operationId: fetchPlaidLinks

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /tools/plaid/core/accounts:
    get:
      description: Fetches all the connected accounts for a Plaid account to a client
      tags: [ tools ]
      operationId: fetchPlaidAccounts

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /tools/plaid/core/transactions:
    get:
      description: Fetches a list of transactions for a given account connected to workflow client and plaid account
      tags: [ tools ]
      operationId: fetchPlaidTransactions

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /utility/crons/process_recurring_engagements:
    post:
      description: Test Cron endpoint
      tags: [ utility ]
      operationId: processRecurringEngagements

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /client_portal/auth/email_new_account_setup:
    post:
      description: Setup account 
      tags: [ client_portal ]
      operationId: emailNewAccountSetup
      security: []
      parameters:
        - name: body
          in: body
          required: true
          schema:
            type: object
            properties:
              contactId:
                type: integer
            required:
              - contactId
      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /client_portal/auth/email_reset_password:
    post:
      description: Reset account password
      tags: [ client_portal ]
      operationId: emailResetPassword
      security: [ ]
      parameters:
        - name: body
          in: body
          description: email reset body request
          required: true
          schema:
            type: object
            properties:
              contactEmail:
                type: string
            required:
              - contactEmail
      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"
        
  /client_portal/user/{contactId}/get_clients:
    get:
      description: get list of clients for user
      tags: [ client_portal ]
      operationId: getClients
      security: [ clientPortalAuthorization: [ default ]  ]
      parameters:
        - name: contactId
          in: path
          description: The ID of the contact
          required: true
          type: string
      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /client_portal/auth/login:
    post:
      description: Login
      tags: [ client_portal ]
      operationId: login
      security: [ ]
      parameters:
        - in: body
          name: body
          description: Login request body
          required: true
          schema:
            type: object
            properties:
              email:
                type: string
              password:
                type: string
            required:
              - email
              - password
      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /client_portal/auth/setup_account:
    post: 
      description: Setup account
      tags: [ client_portal ]
      operationId: setupAccount
      security: []
      parameters:
        - in: body
          name: body
          description: Setup account request body
          required: true
          schema:
            type: object
            properties:
              email:
                type: string
              password:
                type: string
              confirmedPassword:
                type: string
              setupToken: 
                type: string
            required:
              - email
              - password
              - confirmedPassword
              - setupToken
      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /client_portal/user/{contactId}:
    get:
      description: Get a user
      tags: [ client_portal ]
      operationId: getUser
      security: [
         clientPortalAuthorization: [ default ] 
      ]
      parameters:
      - name: contactId
        in: path
        description: The ID of the contact
        required: true
        type: integer
      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /client_portal/file/upload:
    post:
      description: Upload a file
      consumes:
        - multipart/form-data
      tags: [ client_portal ]
      operationId: uploadFile
      security: [
         clientPortalAuthorization: [ default ] 
      ]
      parameters:
        - name: companyId
          in: formData
          description: Company ID
          required: true
          type: integer
        - name: clientId
          in: formData
          description: Client ID
          required: true
          type: integer
        - name: files
          in: formData
          description: Files to upload
          # required is not supported for files with swagger
          type: array
          items:
            type: string
            format: binary

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /client_portal/file/remove:
    post:
      description: Delete a file
      tags: [ client_portal ]
      operationId: deleteFile
      security: [
         clientPortalAuthorization: [ default ] 
      ]
      parameters:
        - name: body
          in: body
          description: Delete file request body
          required: true
          schema:
            type: object
            properties:
              documentId:
                type: integer
              clientId:
                type: integer
              companyId:
                type: integer
            required:
              - documentId
              - clientId
              - companyId
      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"
  
  /client_portal/file/list:
    get:
      description: List files
      tags: [ client_portal ]
      operationId: listFiles
      security:
        - clientPortalAuthorization: [ default ]
      parameters:
        - name: clientId
          in: query
          description: The client ID
          required: true
          type: integer
        - name: search
          in: query
          description: Search term
          required: false
          type: string
      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /client_portal/file/download:
    get:
      description: Download a file
      tags: [ client_portal ]
      operationId: downloadFile
      security: [
         clientPortalAuthorization: [ default ] 
      ]
      parameters:
        - name: companyId
          in: query
          description: Company ID
          required: true
          type: integer
        - name: documentId
          in: query
          description: Document ID
          required: true
          type: integer
        - name: clientId
          description: client id of user
          in: query
          required: true
      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /client_portal/google_drive/auth_url:
    get:
      description: Authenticate with Google Drive
      tags: [ client_portal ]
      operationId: auth_url
      security: []
      parameters:
        - name: companyId
          in: query
          description: Company ID
          required: true
          type: integer
        - name: clientId
          in: query
          description: Client ID
          required: true
          type: string
        - name: clientSecret
          in: query
          description: Client Secret
          required: true
          type: string
        - name: sharedDriveName
          in: query
          description: Shared drive name where files are stored
          required: true
          type: string

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"
  
  /client_portal/google_drive/callback:
    get:
      description: set token of google drive
      tags: [ client_portal ]
      operationId: callback
      security: []
      parameters:
        - name: code
          in: query
          required: true
          type: string
        - name: state
          in: query
          description: proxy state that is passed from auth url
          required: true
          type: string
      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /client_portal/auth/{token}:
    get: 
      description: Verify account setup auth token
      tags: [ client_portal ]
      operationId: verifyToken
      security: []
      parameters:
        - name: token
          in: path
          required: true
          type: string
      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"
            
      

definitions:

  Request:
    description: Basic Request

  Response:
    description: A standard error response for all API methods.
`;
