// External Libraries
import React from "react";
import * as Icon from "react-feather";
import moment from "moment";
import { toast as alert } from "react-toastify";
import queryString from "query-string";
import Board from "react-trello";
import { Trello, ListChecks } from "lucide-react";

// Services
import TaskService from "../../services/TaskService";

// Common Components
import WComponent from "../common/WComponent";
import TaskModal from "./TaskModal";
import TaskFilterModal from "./TaskFilterModal";
import Tabs from "../common/tabs/Tabs";
import Tab from "../common/tabs/Tab";
import ViewList from "../workflow/ViewList/ViewList";
import UserBubbles from "../common/UserBubbles/UserBubbles";
import DeleteConfirmationModal from "../common/modal/confirmation_modal/ConfirmationModal";

// Constants
import WORKFLOW from "../../constants/Workflow";
import { DEADLINES, ORDER_QUERIES, STATUSES } from "../../constants/Tasks";

// Styling
import "./tasks.css";
import UserService from "../../services/UserService";
import UtilityService from "../../services/UtilityService";

const KANBAN_STYLES = {
	deadline: "deadline",
	status: "status"
};

class Tasks extends WComponent {
	constructor(props) {
		super(props);

		this.state = {
			loading: true,

			tasks: [],
			taskId: "new",
			search: "",

			sortField: ORDER_QUERIES.deadline,
			sortOrder: "asc",

			showTaskModal: false,
			showKanban: true,
			showFilterModal: false,
			showViews: true,
			showFilters: true,

			kanbanStyle: KANBAN_STYLES.status,

			isViewChanged: false,

			clientId: 0,
			engagementUserId: 0,
			assignedStatuses: [],
			dueDate: WORKFLOW.defaultDateFunction,
			engagementId: 0,

			assignedUsers: [],
			showDeleteConfirmation: false,
			onConfirmDeleteTaskProxyState: null
		};

		this.taskModal = null;
		this.onNewTask = this.onNewTask.bind(this);
		this.onTaskSelect = this.onTaskSelect.bind(this);
		this.onTaskClose = this.onTaskClose.bind(this);
		this.onKanbanConfirmDeleteClick = this.onKanbanConfirmDeleteClick.bind(this);
	}

	componentDidMount() {
		this.resetComponent();
	}

	componentDidUpdate(prevProps) {
		let newProps = this.props;
		let oldProps = prevProps;

		if (
			oldProps.engagementId !== newProps.engagementId ||
			oldProps.title !== newProps.title ||
			oldProps.showViews !== newProps.showViews ||
			oldProps.showFilters !== newProps.showFilters
		) {
			this.resetComponent();
		}
	}

	resetComponent = async () => {
		let { clientId, engagementId, title, showViews, showFilters } = this.props;

		let user = await UserService.getUserData();

		let isViewingTasksOnMyTasksPage = UtilityService.isNullish(engagementId) && UtilityService.isNullish(clientId);

		let assignedUsers = [];

		if (isViewingTasksOnMyTasksPage) {
			assignedUsers = [
				{
					user_id: user.userId,
					userName: user.firstName + " " + user.lastName
				}
			];
		}

		await this.update({
			title: title || "Tasks",
			clientId: clientId || 0,
			engagementId: engagementId || 0,

			// The default view should be the currently logged in users tasks
			assignedUsers: assignedUsers,
			assignedStatuses: [],
			dueDate: WORKFLOW.defaultDateFunction,
			showDeleteConfirmation: false,
			onConfirmDeleteTaskProxyState: null,

			showViews,
			showFilters
		});

		await this.update({
			loading: true
		});

		await this.fetchTasks();

		await this.update({
			loading: false
		});

		if (this.props.location.search) {
			let params = queryString.parse(this.props.location.search);

			if (params.taskId) {
				this.onTaskSelect(params.taskId);
			}
		}
	};

	sortBy = async field => {
		let { sortOrder } = this.state;

		await this.update({
			sortField: field,
			sortOrder: sortOrder === "desc" ? "asc" : "desc"
		});

		await this.fetchTasks();
	};

	fetchTasks = async () => {
		let { search, sortField, sortOrder, clientId, engagementId, assignedUsers, assignedStatuses, dueDate } = this.state;

		let tasks = await TaskService.list({
			searchTerm: search,
			sortOrder,
			sortField,

			clientId,
			engagementId,

			assignedUsers,
			assignedStatuses,
			dueDate
		});

		await this.update({
			tasks
		});
	};

	onSearch = async event => {
		await this.update({
			search: event.target.value
		});

		await this.fetchTasks();
	};

	onNewTask = async () => {
		await this.update({
			taskId: "new",
			showTaskModal: true
		});
	};

	onTaskSelect = async taskId => {
		this.update({
			taskId: taskId,
			showTaskModal: true
		});
	};

	onTaskClose = async () => {
		await this.update({
			loading: true,
			showTaskModal: false
		});

		await this.fetchTasks();

		await this.update({
			loading: false
		});
	};

	onShowKanban = () => {
		this.update({
			showKanban: true
		});
	};

	onHideKanban = () => {
		this.update({
			showKanban: false
		});
	};

	truncateTaskName = ({ name }) => {
		return name.length > 20 ? name.substring(0, 20) + " ..." : name;
	};

	rerenderTask = async ({ task }) => {
		let { tasks } = this.state;

		let newTasks = tasks.slice();

		for (let t of tasks) {
			if (t.id === task.id) {
				t.name = task.name;
				t.description = task.description;
				t.status = task.status;
			}
		}

		await this.update({
			tasks: newTasks
		});
	};

	onTaskStatusUpdate = async ({ taskId, status }) => {
		let updatedTask = null;

		try {
			updatedTask = await TaskService.update({ taskId, status });

			alert.success("Updated Successfully!");
		} catch (error) {
			console.log(error);
			alert.error("Updated error ...");
		}

		await this.rerenderTask({ task: updatedTask });
	};

	onCardMoveAcrossLanes = (fromLaneId, toLaneId, cardId, index) => {
		this.onTaskStatusUpdate({ taskId: cardId, status: toLaneId });
	};

	onCardClick = (cardId, laneId) => {
		this.onTaskSelect(cardId);
	};

	onDefaultViewSelected = async () => {
		await this.resetComponent();
	};

	onViewSelected = async ({ view }) => {
		let { filters, sort } = JSON.parse(view.schema);

		let { clientId, engagementId, assignedUsers, assignedStatuses, dueDate } = filters;
		let { sortField, sortOrder } = sort;

		await this.update({
			engagementId,
			clientId,

			assignedUsers,
			assignedStatuses,
			dueDate,

			sortField,
			sortOrder,
			isViewChanged: false
		});

		await this.fetchTasks({});
	};

	getFilterSchema = () => {
		// TODO - Should add search to the view
		let { sortField, sortOrder, clientId, engagementId, assignedUsers, assignedStatuses, dueDate } = this.state;

		let schema = {
			filters: {
				clientId,
				engagementId,

				assignedUsers,
				assignedStatuses,
				dueDate
			},
			columns: [], // Add functionality later
			sort: {
				sortField,
				sortOrder
			}
		};

		return schema;
	};

	buildStatusKanbanStyle = () => {
		let { tasks } = this.state;

		let lanes = Object.values(STATUSES).map(status => {
			return {
				id: status.id,
				title: status.name,
				cards: [],
				style: {
					backgroundColor: status.color
				},
				cardStyle: {
					borderLeft: `4px solid ${status.color}`
				}
			};
		});

		for (let task of tasks) {
			for (let lane of lanes) {
				if (task.status === lane.id) {
					lane.cards.push({
						id: task.id,
						task
					});
				}
			}
		}

		const data = {
			lanes
		};

		return data;
	};

	buildDeadlineKanbanStyle = () => {
		let { tasks } = this.state;

		let lanes = Object.values(DEADLINES).map(deadline => {
			return {
				id: deadline.id,
				title: deadline.name,
				range: deadline.range,
				cards: [],
				style: {
					backgroundColor: deadline.color
				},
				cardStyle: {
					borderLeft: `4px solid ${deadline.color}`
				}
			};
		});

		// Object keeps track if a task has been categorized already
		let categorizedTasks = {};

		for (let task of tasks) {
			for (let lane of lanes) {
				// Parse deadline from the task payload
				let deadline = moment(task.deadline);

				// Range is stored within the lange object
				let { start, end } = lane.range;

				// Confirm if the task is within the deadline range
				let isWithinDeadlineRange = deadline.isBetween(start, end, "hour", "[]");

				// Confirm that the task is within the range set for this particular lane and that we have not already categorized it before
				if (isWithinDeadlineRange && !categorizedTasks[task.id]) {
					// Cache a true value to indicate we have categorized this particular task
					categorizedTasks[task.id] = true;

					// Push the task on top the react-trello payload
					lane.cards.push({
						id: task.id,
						task
						// title: this.truncateTaskName({ name: task.name }),
						// description: task.description,
						// label: moment(task.deadline).format("YYYY-MM-DD"),
						// metadata: task
					});
				}
			}
		}

		const data = {
			lanes
		};

		return data;
	};

	async deleteTask(taskId, engagementId) {
		try {
			await TaskService.update({ taskId, engagementId, isDeleted: true });
			alert.success("Updated Successfully!");
			this.resetComponent();
		} catch (error) {
			alert.error("Task update Error");
			console.error(error);
		}
	}

	async onKanbanDeleteClick(taskId, engagementId) {
		await this.update({
			showDeleteConfirmation: true,
			onConfirmDeleteTaskProxyState: {
				taskId,
				engagementId
			}
		});
	}

	async onKanbanConfirmDeleteClick({ confirmed, proxyState }) {
		await this.update({ showDeleteConfirmation: false });
		if (!confirmed) {
			return;
		}

		await this.deleteTask(proxyState.taskId, proxyState.engagementId);
		await this.update({ onConfirmDeleteTaskProxyState: null });
	}

	CustomCard = props => {
		let { id, task, onClick } = props;

		return (
			<div key={id} className="task-card" onClick={onClick}>
				{task.Client && <div className="task-card__label">Client: {task.Client.name}</div>}

				<div className="task-card__divider" />

				<div className="task-card__title">{task.name}</div>
				<div className="task-card__description">{task.description}</div>

				<div className="task-card__divider" />

				{task.Engagement && <div className="task-card__eng-name">{task.Engagement.name}</div>}

				<div className="task-card__dates">
					<div className="task-card__dates__item">Created - {moment(task.created_at).format("YYYY-MM-DD")}</div>
					<div className="task-card__dates__item">Due - {moment(task.deadline).format("YYYY-MM-DD")}</div>
				</div>
			</div>
		);
	};

	renderKanban() {
		let { kanbanStyle, engagementId } = this.state;

		let data = {};

		if (kanbanStyle === KANBAN_STYLES.status) {
			data = this.buildStatusKanbanStyle();
		} else if (kanbanStyle === KANBAN_STYLES.deadline) {
			data = this.buildDeadlineKanbanStyle();
		}

		return (
			<>
				<div className="task-kanban-container">
					<Board
						components={{
							Card: this.CustomCard
						}}
						cardDraggable={kanbanStyle === KANBAN_STYLES.status}
						className="task-kanban-container__board"
						style={{ backgroundColor: "#eaedf3" }}
						data={data}
						onCardClick={this.onCardClick}
						onCardMoveAcrossLanes={this.onCardMoveAcrossLanes}
						onCardDelete={taskId => this.onKanbanDeleteClick(taskId, engagementId)}
					/>
				</div>

				<DeleteConfirmationModal
					title="Delete Task?"
					warning="Are you sure you want to delete this task?"
					show={this.state.showDeleteConfirmation}
					onClose={this.onKanbanConfirmDeleteClick}
					proxyState={this.state.onConfirmDeleteTaskProxyState}
				/>
			</>
		);
	}

	renderSortIcon = field => {
		let { sortField, sortOrder } = this.state;

		return sortField === field && (sortOrder === "desc" ? <Icon.ChevronUp size={14} /> : <Icon.ChevronDown size={14} />);
	};

	onKanbanStyleSelect = async kanbanStyle => {
		await this.update({ kanbanStyle: kanbanStyle.id });
	};

	onTaskFilterModalClose = async () => {
		await this.update({ showFilterModal: false });
	};

	onShowFilterModal = async () => {
		await this.update({
			showFilterModal: true
		});
	};

	onFilterChange = async ({ filters, columns }) => {
		let { clientId, engagementId, assignedUsers, assignedStatuses, dueDate } = filters;

		await this.update({
			clientId,
			engagementId,

			assignedUsers,
			assignedStatuses,
			dueDate,

			isViewChanged: true
		});

		await this.fetchTasks({});
	};

	render() {
		let {
			loading,
			taskId,

			search,

			// Filters
			clientId,
			engagementId,
			assignedUsers,
			assignedStatuses,
			dueDate,

			showKanban,
			kanbanStyle,

			showTaskModal,
			showFilterModal,
			showViews,
			showFilters,

			title,

			tasks,

			isViewChanged
		} = this.state;

		return (
			<div className="container">
				<div className="container-header">
					<div className="container-header-title">
						<h3 className="container-header-title__sub">Workflow</h3>
						<h1 className="container-header-title__name">{title}</h1>
					</div>
					<div className="container-header-options">
						<div className="toggles">
							<div className={`toggles__item ${showKanban ? "toggles__item--active" : ""}`} onClick={this.onShowKanban}>
								<Trello size={22} />
							</div>
							<div className={`toggles__item ${!showKanban ? "toggles__item--active" : ""}`} onClick={this.onHideKanban}>
								<ListChecks size={22} />
							</div>
						</div>

						<Tabs onSelect={this.onKanbanStyleSelect} selected={kanbanStyle}>
							<Tab id={"deadline"} value="Deadline" />
							<Tab id={"status"} value="Status" />
						</Tabs>
						{showFilters && (
							<button className="container-add" onClick={this.onShowFilterModal} type="button">
								<Icon.Sliders size={18} />
							</button>
						)}
						<button className="container-add" type="button" onClick={this.onNewTask}>
							<Icon.Plus size={18} />
						</button>

						<input
							className="container-search container-search--tasks"
							type="search"
							name="search"
							placeholder="Search..."
							id="search-client-field"
							value={search}
							onChange={this.onSearch}
							autoComplete="off"
						/>
					</div>
				</div>

				<div className="test-container">
					{showViews && (
						<ViewList
							type={"task"}
							isViewChanged={isViewChanged}
							getSchema={this.getFilterSchema}
							onViewSelected={this.onViewSelected}
							onDefaultViewSelected={this.onDefaultViewSelected}
						/>
					)}

					{loading && <div className="container-loader">Loading ...</div>}

					{tasks.length === 0 && !loading && (
						<div className="container-empty">
							<div>
								<Icon.Frown size={128} />
							</div>
							<div>... no tasks ...</div>
						</div>
					)}

					{tasks.length > 0 && !loading && (
						<div className="scroll-container">
							{showKanban && this.renderKanban()}
							{!showKanban && (
								<div className="list-container">
									<div className="list-header list-item">
										<div onClick={() => this.sortBy(ORDER_QUERIES.name)} className="list-column">
											Title {this.renderSortIcon(ORDER_QUERIES.name)}
										</div>
										<div onClick={() => this.sortBy(ORDER_QUERIES.description)} className="list-column">
											Description {this.renderSortIcon(ORDER_QUERIES.description)}
										</div>
										<div className="list-column">Users</div>
										<div onClick={() => this.sortBy(ORDER_QUERIES.status)} className="list-column">
											Status {this.renderSortIcon(ORDER_QUERIES.status)}
										</div>
										<div onClick={() => this.sortBy(ORDER_QUERIES.deadline)} className="list-column">
											Deadline {this.renderSortIcon(ORDER_QUERIES.deadline)}
										</div>
									</div>

									{tasks.map((task, index) => {
										let odd = index % 2 === 0;

										return (
											<div
												className={`list-item ${odd ? "list-item--stripe" : ""}`}
												key={index}
												onClick={() => this.onTaskSelect(task.id)}
												style={{ backgroundColor: STATUSES[task.status].color }}
											>
												<div className="list-column">{task.name}</div>
												<div className="list-column">{task.description}</div>
												<div className="list-column">
													<div id="user-column" className="list-column">
														{<UserBubbles engagementUsers={task.task_users} />}
													</div>
												</div>
												<div className="list-column">{WORKFLOW.taskStatus[task.status].value}</div>
												<div className="list-column">{moment(task.deadline).format("MMMM Do YYYY @ h:mma")}</div>
											</div>
										);
									})}
								</div>
							)}
						</div>
					)}
				</div>

				<TaskModal show={showTaskModal} clientId={clientId} taskId={taskId} engagementId={engagementId} onClose={this.onTaskClose} {...this.props} />

				<TaskFilterModal
					show={showFilterModal}
					onClose={this.onTaskFilterModalClose}
					onFilterChange={this.onFilterChange}
					filters={{ clientId, engagementId, assignedUsers, assignedStatuses, dueDate }}
				/>
			</div>
		);
	}
}

export default Tasks;
