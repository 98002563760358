import React from "react";
import Modal from "react-responsive-modal";
import { toast as alert } from "react-toastify";

import TextAreaField from "../common/text_area_field/TextAreaField";
import NoteService from "../../services/NoteService";

import "../common/modal/modal.css";
import WComponent from "../common/WComponent";

class NoteModal extends WComponent {
	constructor() {
		super();
		this.state = {
			noteId: "new",
			content: "",

			loading: false,
			show: false
		};
	}

	componentDidMount() {
		this.resetComponent();
	}

	componentDidUpdate(prevProps) {
		let { noteId } = this.props;

		if (prevProps.noteId !== noteId) {
			this.resetComponent();
		}
	}

	async resetComponent() {
		let { noteId } = this.props;

		await this.update({
			loading: true,
			noteId,
			content: ""
		});

		if (noteId !== "new") {
			await this.fetchNote();
		}

		await this.update({
			loading: false
		});
	}

	fetchNote = async () => {
		let { noteId } = this.props;

		let note = await NoteService.fetchNote({ noteId });

		await this.update({ noteId: note.id, content: note.content });
	};

	show() {
		this.update({
			show: true
		});
	}

	updateNote = async () => {
		let { noteId, content } = this.state;

		alert.info("Updating ...");

		this.update({
			loading: true
		});

		let success = await NoteService.updateNote({ noteId, content });

		if (!success) {
			alert.info("Failed to update note.");
		} else {
			alert.info("Updated note successfully!");
		}

		this.update({
			loading: false
		});
	};

	isValid = () => {
		let { content } = this.state;

		return content.length > 0;
	};

	createNote = async () => {
		let { content } = this.state;
		let { engagementId, clientId, shouldPin } = this.props;

		if (!this.isValid()) {
			alert.error("Missing fields!");
			return;
		}

		this.update({
			loading: true
		});

		let success = await NoteService.createNote({ content, engagementId, clientId, isPinned: shouldPin });

		if (!success) {
			alert.info("Failed to create note.");
		} else {
			alert.info("Created note successfully!");
		}

		this.update({
			loading: false
		});
	};

	isNewNote = () => {
		return this.state.noteId === "new";
	};

	onChange = async e => {
		let target = e.target;
		let value = target.type === "checkbox" ? target.checked : target.value;
		await this.update({
			[target.name]: value
		});
	};

	onClose = async confirmed => {
		if (confirmed) {
			if (this.isNewNote()) {
				await this.createNote();
			} else {
				await this.updateNote();
			}
		}

		await this.update({
			show: false
		});

		if (this.props.onClose) {
			this.props.onClose();
		}
	};

	render() {
		let { show, content, loading } = this.state;

		return (
			<Modal open={show} onClose={() => this.onClose(false)} center>
				<div className="modal-title">{this.isNewNote() ? "New Note" : "Note"}</div>
				<div className="modal-content">
					{loading && <div className="loader">Loading ...</div>}
					{!loading && (
						<TextAreaField
							className="text-area-field__container__input--large"
							width={100}
							height={100}
							title="Note Contents"
							type="text"
							name="content"
							value={content}
							onChange={this.onChange}
						/>
					)}
				</div>
				<div className="btn-group">
					<div className="btn" onClick={() => this.onClose(true)}>
						{this.isNewNote() ? "Create Note" : "Update Note"}
					</div>
					<div className="btn" onClick={() => this.onClose(false)}>
						Cancel
					</div>
				</div>
			</Modal>
		);
	}
}

export default NoteModal;
