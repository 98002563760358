import React from "react";
import * as Icon from "react-feather";
import moment from "moment";

import { ORDER_QUERIES } from "../../../constants/EngagementTemplates";

import EngagementService from "../../../services/EngagementService";

import WComponent from "../../common/WComponent";
import EngagementTemplateModal from "./EngagementTemplateModal";

import "./engagement-templates.css";

class EngagementTemplates extends WComponent {
	constructor(props) {
		super(props);

		this.state = {
			loading: true,

			engagementTemplates: [],
			engagementTemplateId: "new",
			search: "",

			filter: "active",

			sortField: ORDER_QUERIES.createdAt,
			sortOrder: "asc",

			showEngagementTemplateModal: false
		};

		// this.taskModal = null;
		// this.onNewTask = this.onNewTask.bind(this);
		// this.onTaskSelect = this.onTaskSelect.bind(this);
		// this.onTaskClose = this.onTaskClose.bind(this);
		// this.onFilterSelect = this.onFilterSelect.bind(this);
	}

	componentDidMount() {
		this.resetComponent();
	}

	componentDidUpdate(prevProps) {
		// let { raw } = this.props;
		// let newProps = raw ? this.props : this.props.match.params;
		// let oldProps = raw ? prevProps : prevProps.match.params;
		// if (oldProps.engagementId !== newProps.engagementId || oldProps.assignedUserId !== newProps.assignedUserId || oldProps.title !== newProps.title) {
		// 	this.resetComponent();
		// }
	}

	resetComponent = async () => {
		await this.update({ loading: true });
		await this.fetchEngagementTemplates();
		await this.update({ loading: false });
	};

	sortBy = async field => {
		let { sortOrder } = this.state;

		await this.update({
			sortField: field,
			sortOrder: sortOrder === "desc" ? "asc" : "desc"
		});

		await this.fetchEngagementTemplates();
	};

	fetchEngagementTemplates = async () => {
		let { sortField, sortOrder, search } = this.state;

		try {
			let engagementTemplates = await EngagementService.fetchEngagementTemplates({ sortField, sortOrder, searchTerm: search });

			await this.update({ engagementTemplates });
		} catch (error) {
			console.log(error);
		}
	};

	onSearch = async event => {
		await this.update({
			search: event.target.value
		});

		await this.fetchEngagementTemplates();
	};

	onNewTemplate = async () => {
		await this.update({
			engagementTemplateId: "new",
			showEngagementTemplateModal: true
		});
	};

	onUpdateTemplate = async engagementTemplateId => {
		await this.update({
			engagementTemplateId: engagementTemplateId,
			showEngagementTemplateModal: true
		});
	};

	onEngagementTemplateModalClose = async () => {
		await this.update({
			loading: true,
			showEngagementTemplateModal: false
		});

		await this.fetchEngagementTemplates();

		await this.update({
			loading: false
		});
	};

	onFilterSelect = async filter => {
		await this.update({ filter });

		await this.fetchEngagementTemplates();
	};

	renderSortIcon = field => {
		let { sortField, sortOrder } = this.state;

		return sortField === field && (sortOrder === "desc" ? <Icon.ChevronUp size={14} /> : <Icon.ChevronDown size={14} />);
	};

	render() {
		let { filter, loading, search, engagementTemplateId, engagementTemplates, showEngagementTemplateModal } = this.state;

		return (
			<div className="container">
				<div className="container-header">
					<div className="container-header-title">
						<h3 className="container-header-title__sub">Workflow</h3>
						<h1 className="container-header-title__name">Engagement Templates</h1>
					</div>
					<div className="container-header-options">
						<div className="toggle">
							<button
								onClick={() => this.onFilterSelect("all")}
								type="submit"
								className={filter === "all" ? "toggle--active" : ""}
								autoComplete="off"
							>
								All
							</button>
						</div>
						<div className="toggle">
							<button
								onClick={() => this.onFilterSelect("active")}
								type="submit"
								className={filter === "active" ? "toggle--active" : ""}
								autoComplete="off"
							>
								Active
							</button>
						</div>

						<button className="container-add" type="button" onClick={this.onNewTemplate}>
							<Icon.Plus size={18} />
						</button>
						<input
							className="container-search"
							type="search"
							name="search"
							placeholder="Search..."
							id="search-client-field"
							value={search}
							onChange={this.onSearch}
							autoComplete="off"
						/>
					</div>
				</div>

				{loading && <div className="container-loader">Loading ...</div>}

				{engagementTemplates.length === 0 && !loading && (
					<div className="container-empty">
						<div>
							<Icon.Frown size={128} />
						</div>
						<div>... no engagement templates ...</div>
					</div>
				)}

				{engagementTemplates.length > 0 && !loading && (
					<div className="list-container">
						<div className="list-header list-item">
							<div onClick={() => this.sortBy(ORDER_QUERIES.name)} className="list-column">
								Name {this.renderSortIcon(ORDER_QUERIES.name)}
							</div>
							<div onClick={() => this.sortBy(ORDER_QUERIES.createdAt)} className="list-column">
								Created At {this.renderSortIcon(ORDER_QUERIES.createdAt)}
							</div>
						</div>

						{engagementTemplates.map((engagementTemplate, index) => {
							let odd = index % 2 === 0;
							return (
								<div
									className={`list-item ${odd ? "list-item--stripe" : ""}`}
									key={index}
									onClick={() => this.onUpdateTemplate(engagementTemplate.id)}
								>
									<div className="list-column">{engagementTemplate.name}</div>
									<div className="list-column">{moment(engagementTemplate.created_at).format("MMMM Do YYYY @ h:mma")}</div>
								</div>
							);
						})}
					</div>
				)}

				<EngagementTemplateModal
					show={showEngagementTemplateModal}
					engagementTemplateId={engagementTemplateId}
					onClose={this.onEngagementTemplateModalClose}
					onCreate={() => {}}
					{...this.props}
				/>
			</div>
		);
	}
}

export default EngagementTemplates;
