import React from "react";
import { Redirect } from "react-router-dom";
import { toast as alert } from "react-toastify";
import UserService from "../../services/UserService";
import TextFieldInput from "../common/text_field_input/TextFieldInput";
import TextAreaField from "../common/text_area_field/TextAreaField";

import "./settings.css";
import CompanyService from "../../services/CompanyService";
import WComponent from "../common/WComponent";

class CompanySettings extends WComponent {
	constructor() {
		super();

		this.state = {
			name: "",
			email: "",
			phone: "",
			sms: "",
			notes: "",

			loading: false,

			search: "",
			users: []
		};

		this.onChange = this.onChange.bind(this);
		this.updateCompany = this.updateCompany.bind(this);
	}

	componentDidMount() {
		this.resetComponent();
	}

	componentDidUpdate() {}

	async resetComponent() {
		await this.update({
			loading: true
		});

		await this.fetchCompany();

		await this.update({
			loading: false
		});
	}

	async fetchCompany() {
		try {
			let response = await CompanyService.get();

			let company = response.data;

			await this.update({
				name: company.name,
				email: company.email,
				phone: company.phone,
				sms: company.sms,
				notes: company.notes
			});
		} catch (error) {
			console.log(error);
		}
	}

	async updateCompany() {
		let { loading, name, email, phone, sms, notes } = this.state;

		if (!this.isValid()) {
			alert.error("Missing fields!");
			return;
		}

		if (loading) {
			alert.info("Updating...");
			return;
		}

		this.update({
			loading: true
		});

		try {
			await CompanyService.update({ name, email, phone, sms, notes });

			alert.success("Updated Successfully!");
		} catch (error) {
			alert.error("Update Error!");
			console.log(error);
		}

		this.update({
			loading: false
		});
	}

	onChange(e) {
		this.update({ [e.target.name]: e.target.value });
	}

	isDisabled() {
		let { loading } = this.state;

		return !this.isValid() || loading;
	}

	isValid() {
		let { name, email, phone, sms } = this.state;
		return name.length > 0 && email.length > 0 && phone.length > 0 && sms.length > 0;
	}

	render() {
		let { name, email, phone, sms, notes } = this.state;

		let userData = UserService.getUserData();

		if (userData.groupId === 2) {
			return <Redirect to={"/dashboard"} />;
		}

		return (
			<div className="company-settings-container">
				<div className="company-settings-header">
					<div className="company-settings-title-container">
						<h3 className="company-settings-subtitle">Settings - Company</h3>
						<h1 className="company-settings-title">{name}</h1>
					</div>
				</div>

				<div className="company-settings-form-container">
					<div className="company-settings-form">
						<div className="fields1">
							<TextFieldInput title="Company Name" name="name" onChange={this.onChange} value={name} autoComplete="off" />

							<TextFieldInput title="Email" name="email" onChange={this.onChange} value={email} autoComplete="off" />

							<TextFieldInput title="Phone" name="phone" onChange={this.onChange} value={phone} autoComplete="off" />

							<TextFieldInput title="SMS" name="sms" onChange={this.onChange} value={sms} autoComplete="off" />
						</div>
						<div className="company-settings-note-container">
							<div>
								<TextAreaField
									title="Notes"
									name="notes"
									onChange={this.onChange}
									value={notes}
									autoComplete="off"
									className="client-details-note"
								/>
							</div>
						</div>
					</div>
					<div className="btn-group">
						<div className={"btn " + (this.isDisabled() ? "disable" : "")} onClick={this.updateCompany}>
							Update
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default CompanySettings;
