import React from "react";
import Modal from "react-responsive-modal";
import { toast as alert } from "react-toastify";

import WComponent from "../../common/WComponent";
import EngagementService from "../../../services/EngagementService";

// import "./client-filter-modal.css";
export default class ClientMultiAction extends WComponent {
	constructor(props) {
		super(props);

		this.state = {
			show: false,
			loading: true,

			clientIds: props.clientIds || [],

			flows: [],
			selectedFlows: {}
		};
	}

	componentDidMount() {
		this.resetComponent();
	}

	componentDidUpdate(prevProps) {
		let { show, clientIds } = this.props;

		if (prevProps.show !== show) {
			this.resetComponent();
		}

		if (JSON.stringify(prevProps.clientIds) !== JSON.stringify(clientIds)) {
			this.resetComponent();
		}
	}

	resetComponent = async () => {
		let { show, clientIds } = this.props;

		await this.update({
			loading: true
		});

		let flows = await EngagementService.fetchFlows({});

		await this.update({
			flows,
			selectedFlows: [],

			clientIds,

			show,
			loading: false
		});
	};

	onClose = async () => {
		if (this.props.onClose) {
			this.props.onClose();
		}
	};

	onCreate = async () => {
		let { clientIds, selectedFlows } = this.state;

		let flowIds = Object.keys(selectedFlows);

		let success = await EngagementService.multiCreateEngagements({ clientIds, flowIds });

		if (!success) {
			alert.error("An error occurred trying to queue engagement creation.");
			return;
		}

		if (success) {
			alert.success(`Queueing ${clientIds * flowIds} new Engagements`);
			return;
		}

		if (this.props.onClose) {
			this.props.onClose();
		}
	};

	onFlowClicked = async ({ flow }) => {
		let { selectedFlows } = this.state;

		if (typeof selectedFlows[flow.id] !== "undefined") {
			delete selectedFlows[flow.id];
		} else {
			selectedFlows[flow.id] = {
				flowStateId: flow.flow_states[0].id,
				name: flow.name,
				code: flow.code
			};
		}

		await this.update({
			selectedFlows
		});
	};

	renderFlows = () => {
		let { flows, selectedFlows } = this.state;

		return (
			<div className="pill-container">
				{flows.map((flow, index) => {
					return (
						<div
							className={`pill ${selectedFlows[flow.id] ? "pill--active" : ""} `}
							onClick={() => this.onFlowClicked({ flow })}
							key={`${index}-${flow.id}`}
						>
							{flow.name}
						</div>
					);
				})}
			</div>
		);
	};

	render() {
		let { show, clientIds } = this.state;
		let { onClose } = this.props;

		return (
			<Modal open={show} onClose={onClose} center>
				<div className="modal-title">Create new Engagements ...</div>
				<div className="modal-content">
					Would you like to create engagements for {clientIds.length} clients?
					<br />
					Workflow will attempt to create engagements for each of the selected clients. Assigned users will be taken from the Team assigned to the
					client.
					<br />
					<br />
					{this.renderFlows()}
				</div>
				<div className="btn-group">
					<div className="btn" onClick={this.onCreate}>
						Create
					</div>
					<div className="btn" onClick={onClose}>
						Cancel
					</div>
				</div>
			</Modal>
		);
	}
}
