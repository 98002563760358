import React from "react";
import { Redirect } from "react-router-dom";
import * as Icon from "react-feather";

import UserService from "../../../services/UserService";

import "./flows.css";
import EngagementService from "../../../services/EngagementService";
import WComponent from "../../common/WComponent";

class Flows extends WComponent {
	constructor() {
		super();

		this.state = {
			loading: false,

			flows: [],
			showMore: null
		};
	}

	async componentDidMount() {
		await this.resetComponent();
	}

	componentDidUpdate() {}

	async resetComponent() {
		await this.fetchFlows();
	}

	fetchFlows = async () => {
		let flows = await EngagementService.fetchFlows({});

		await this.update({
			flows
		});
	};

	onChange(e) {
		this.update({ [e.target.name]: e.target.value });
	}

	renderStates(flow) {
		return (
			<div className="flows__item__states">
				Flow States:
				{flow.flow_states.map(fs => {
					let style = { backgroundColor: fs.color };
					return (
						<div className="flow__item__states__item" style={style}>
							{fs.name}
						</div>
					);
				})}
			</div>
		);
	}

	onShowMore = async flowId => {
		let { showMore } = this.state;

		showMore = showMore === flowId ? null : flowId;

		await this.update({
			showMore
		});
	};

	render() {
		let { flows, showMore } = this.state;

		let userData = UserService.getUserData();

		if (userData.groupId === 2) {
			return <Redirect to={"/dashboard"} />;
		}

		return (
			<div className="container">
				<div className="container-header">
					<div className="container-header-title">
						<h3 className="container-header-title__sub">Settings - Flows</h3>
						<h1 className="container-header-title__name">Flows</h1>
					</div>
				</div>

				<div className="flows">
					{flows.map(f => {
						return (
							<div key={f.id} className="flows__item">
								<div className="flows__item__details">
									<div className="flows__item__details__item">Name: {f.name}</div>
									<div className="flows__item__details__item">Code: {f.code}</div>
									<div className="flows__item__details__itemz">Default Fiscal Frequency: {f.frequency} months</div>
									<div className="flows__item__details__actions" onClick={() => this.onShowMore(f.id)}>
										<Icon.ChevronDown />
									</div>
								</div>

								{showMore === f.id && this.renderStates(f)}
							</div>
						);
					})}
				</div>
			</div>
		);
	}
}

export default Flows;
