// External Libraries
import React from "react";
import * as Icon from "react-feather";

// Services
import PlaidService from "../../../services/PlaidService";

// Common Components
import WComponent from "../../common/WComponent";

// Components
import ReconciliationTool from "./ReconciliationTool/ReconciliationTool";
import TransactionsTool from "./TransactionsTool/TransactionsTool";
import HstReconciliationTool from "./HstReconciliationTool/HstReconciliationTool";

// Styling
import "./tools.css";

const TOOLS = {
	transactions: {
		type: "transactions",
		title: "Bank Records",
		description: "View transaction history for this client's bank records.",
		Icon: Icon.DollarSign
	},
	reconciliationTool: {
		type: "reconciliationTool",
		title: "Reconciliation Tool",
		description: "Helper tool to reconcile CRA entries and the General Ledger for a given client",
		Icon: Icon.RefreshCcw
	},
	gstReconciliationTool: {
		type: "gstReconciliationTool",
		title: "GST Reconciliation Tool",
		description: "Helper tool to reconcile GST entries and the General Ledger for a given client",
		Icon: Icon.RefreshCcw
	}
};

class Tools extends WComponent {
	constructor(props) {
		super(props);

		this.state = {
			selectedTool: null,

			isBankAccountConnected: false
		};
	}

	async componentDidMount() {
		let { clientId } = this.props;

		let isBankAccountConnected = await PlaidService.isPlaidAccountConnected({ clientId });

		this.update({
			isBankAccountConnected
		});
	}

	onSelectTool = type => {
		let { isBankAccountConnected } = this.state;

		if (!isBankAccountConnected && type === TOOLS.transactions.type) {
			return;
		}

		this.update({
			selectedTool: type
		});
	};

	renderToolOption(toolOption) {
		let { type, title, description, Icon } = toolOption;
		let { isBankAccountConnected } = this.state;

		let toolsItemClasses = ["tools__item"];

		if (!isBankAccountConnected && type === TOOLS.transactions.type) {
			toolsItemClasses.push("tools__item--is-disabled");
		}

		return (
			<div className={toolsItemClasses.join(" ")} onClick={() => this.onSelectTool(type)}>
				<div className="tools__item__icon">
					<Icon size={36} />
				</div>
				<div className="tools__item__text">
					<div className="tools__item__text__title">{title}</div>
					<div className="tools__item__text__blurb">{description}</div>
				</div>
			</div>
		);
	}

	render() {
		let { clientId } = this.props;
		let { selectedTool } = this.state;

		const toolsOptionArray = Object.values(TOOLS);

		return (
			<>
				{!selectedTool && (
					<div className="container">
						<div className="container__body">
							<div className="tools">{toolsOptionArray.map(toolOption => this.renderToolOption(toolOption))}</div>
						</div>
					</div>
				)}

				{selectedTool === TOOLS.reconciliationTool.type && <ReconciliationTool clientId={clientId} />}
				{selectedTool === TOOLS.transactions.type && <TransactionsTool clientId={clientId} />}
				{selectedTool === TOOLS.gstReconciliationTool.type && <HstReconciliationTool clientId={clientId} />}
			</>
		);
	}
}

export default Tools;
