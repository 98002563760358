// External Libraries
import React from "react";
import Modal from "react-responsive-modal";

// Common Components
import WComponent from "../../common/WComponent";
import PlaidService from "../../../services/PlaidService";

// Styles
// import "../common/modal/modal.css";
import "./plaid-connection-modal.css";
import ConfigurationService from "../../../services/ConfigurationService";

class PlaidConnectionModal extends WComponent {
	constructor() {
		super();
		this.state = {
			clientId: null,
			status: null,
			connectionLink: "",
			accounts: [],

			loading: false,
			show: false
		};
	}

	componentDidMount() {
		this.resetComponent();
	}

	componentDidUpdate(prevProps) {
		let { clientId, show } = this.props;

		if (prevProps.clientId !== clientId || prevProps.show !== show) {
			this.resetComponent();
		}
	}

	async resetComponent() {
		let { clientId, show } = this.props;

		if (!show) {
			return;
		}

		await this.update({
			show,
			loading: true,
			clientId,
			content: ""
		});

		await this.fetchPlaidIntegration();

		await this.update({
			loading: false
		});
	}

	fetchPlaidIntegration = async () => {
		let { clientId } = this.props;

		let plaidLink = await PlaidService.fetchLinks({ clientId });

		if (!plaidLink) {
			await this.update({
				status: null
			});
			return;
		}

		let connectionLink = `${ConfigurationService.resolveApi().clientPortal}connect/${plaidLink.public_id}`;

		if (plaidLink.status === "active") {
			await this.update({
				status: plaidLink.status,
				connectionLink
			});

			return;
		}

		// Default case is that the plaidLink is completed
		let plaidPayload = JSON.parse(plaidLink.payload);
		let accounts = plaidPayload.accounts;

		await this.update({
			status: plaidLink.status,
			connectionLink,
			accounts
		});
	};

	show() {
		this.update({
			show: true
		});
	}

	onClose = async confirmed => {
		await this.update({
			show: false
		});

		if (this.props.onClose) {
			this.props.onClose();
		}
	};

	onGenerateConnectionLinkClicked = async () => {
		let { clientId } = this.props;

		await this.update({
			loading: true
		});

		let plaidLink = await PlaidService.createPlaidShortLink({ clientId });

		if (!plaidLink) {
			//error
			return;
		}

		this.fetchPlaidIntegration();

		await this.update({
			loading: false
		});
	};

	doesPlaidLinkExist() {
		let { status } = this.state;
		return status !== null;
	}

	renderAccounts() {
		let { accounts } = this.state;

		return (
			<div className="pcm__accounts">
				Accounts:
				{accounts.map(account => {
					return <div className="pcm__accounts__item">{account.name}</div>;
				})}
			</div>
		);
	}

	renderNone() {
		let { status } = this.state;

		if (status) {
			return null;
		}

		return <div className="pcm__status">Generate a new connection link to share with the client</div>;
	}

	renderActive() {
		let { status, connectionLink } = this.state;

		if (status !== "active") {
			return null;
		}

		return (
			<>
				<div className="pcm__status">A connection link has been generated for this client, but they have not connected any accounts yet.</div>
				<div className="pcm__link">
					Connection Link: <a href={connectionLink}>{connectionLink}</a>
				</div>
			</>
		);
	}

	renderCompleted() {
		let { status, connectionLink } = this.state;

		if (status !== "completed") {
			return null;
		}

		return (
			<>
				<div className="pcm__status">This client has connected their bank accounts to Workflow.</div>
				<div className="pcm__link">
					Connection Link: <a href={connectionLink}>{connectionLink}</a>
				</div>
				{this.renderAccounts()}
			</>
		);
	}

	render() {
		let { show, loading } = this.state;

		return (
			<Modal open={show} onClose={() => this.onClose(false)} center>
				<div className="modal-title">{"Bank Account Details"}</div>
				<div className="modal-content">
					{loading && <div className="loader">Loading ...</div>}
					{!loading && (
						<div className="pcm">
							{this.renderNone()}
							{this.renderActive()}
							{this.renderCompleted()}
						</div>
					)}
				</div>
				<div className="btn-group">
					{!this.doesPlaidLinkExist() && (
						<div className="btn" onClick={this.onGenerateConnectionLinkClicked}>
							Generate Connection Link
						</div>
					)}
					<div className="btn" onClick={() => this.onClose(false)}>
						Close
					</div>
				</div>
			</Modal>
		);
	}
}

export default PlaidConnectionModal;
